<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="12" md="8">
        <v-card class="ma-4 pa-2">
          <v-card-title>{{ t('Restablecer_Contraseña') }}</v-card-title>
          <v-card-text>
            <form @submit.prevent="requestReset">
              <v-text-field v-model="email" :label="t('Email')" type="email" required></v-text-field>
              <v-btn type="submit" color="primary">{{ t('Enviar_solicitud') }}</v-btn>
            </form>
          </v-card-text>
          <v-card-actions v-if="message">
            <v-spacer></v-spacer>
            <v-text color="red">{{ message }}</v-text>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>

import { useI18n } from 'vue-i18n';
const { t } = useI18n();

</script>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      email: '',
      message: ''
    };
  },
  methods: {
    requestReset() {
      const hostname = window.location.hostname;
      axios.post(`https://${hostname}/api/passreset/`, {
        email: this.email
      })
        .then(() => {
          // Mensaje de éxito al usuario para informarle de que se ha enviado el correo.
          this.message = "Se ha enviado un correo para restablecer tu contraseña. Por favor, revisa tu bandeja de entrada.";
        })
        .catch(error => {
          // Puedes manejar errores específicos dependiendo del error que devuelva el backend.
          // Por ahora, simplemente mostraremos un mensaje genérico.
          this.message = "Hubo un problema al intentar enviar el correo. Por favor, intenta nuevamente.";
          console.error(error);
        });
    }
  }
}
</script>
  
<style scoped>
/* Aquí puedes agregar tus estilos específicos para este componente */
.password-reset {
  /* Estilo de ejemplo */
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style>
  