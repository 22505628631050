<template>
  <v-container class="fill-height">
    <v-row no-gutters>
      <v-col cols="12" sm="6" class="pa-6">
        <!-- Capçalera i Introducció -->
        <v-row>
          <v-col cols="12">
            <h1>{{
              t('Aprende') }}</h1>
            <p>{{
              t('GineSIM_aprende_descripcion') }}</p>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <h2>{{
              t('Aprendizaje_Interactivo_Online') }}</h2>
            <p>{{
              t('Aprendizaje_Interactivo_Online_descripcion') }}</p>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <h2>{{
              t('Simulación_Práctica') }}</h2>
            <p>{{
              t('Simulación_Práctica_descripcion') }}</p>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <h2>{{
              t('Eres_Residente') }}</h2>
            <p>{{
              t('Eres_Residente_descripcion') }}</p>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="6">
        <LlistaActivitats :cursos="cursosEnCurso" :error="t('No_estás_inscrito_a_ninguna_actividad')" :titol="t('Tus_actividades')" :url="url" :mostrarAppend="false" :isLoading="isLoadingCursos">
        </LlistaActivitats>
        <LlistaActivitats :cursos="cursosCompletados" :error="t('Todavía_no_has_completado_ninguna_actividad')" :titol="t('Actividades_completadas')" :url="url" :mostrarAppend="true" :isLoading="isLoadingCursos">
        </LlistaActivitats>
        <v-col v-if="error" class="text-center">
          <p>{{ error }}</p>
          <v-btn class="ma-4" color="primary" :to="`/perfil`">{{
            t('Accede_a_tu_perfil') }}</v-btn>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LlistaActivitats from './LlistaActivitats.vue';
import axios from 'axios'
import { useI18n } from 'vue-i18n';
export default {
  setup() {
    const { t } = useI18n();
    return {
      t
    };
  },
  components: {
    LlistaActivitats
  },
  data() {
    return {
      cursos: [],
      error: '',
      url: '/curso/',
      isLoadingCursos: true,
    }
  },
  computed: {
    cursosEnCurso() {
      // Cursos 'en curso' son aquellos donde 'nota_final' es null
      return this.cursos.filter(curso => curso.notas.nota_final === null);
    },
    cursosCompletados() {
      // Cursos 'completados' son aquellos donde 'nota_final' no es null
      return this.cursos.filter(curso => curso.notas.nota_final !== null);
    }
  },
  methods: {
    async fetchCursos() {
      try {
        const hostname = window.location.hostname;
        const token = localStorage.getItem('authToken');
        const response = await axios.get(`https://${hostname}/api/apren/`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });
        this.cursos = response.data;
        this.error = ''; // Reinicia el mensaje de error en caso de éxito       
      } catch (error) {
        console.error("Error al obtener los cursos:", error);
      }
      this.isLoadingCursos = false;
    },
    solicitarAcceso(cursoId) {
      console.log(`Solicitando acceso al curso ${cursoId}`);
    },

  },
  created() {
    this.fetchCursos()
  }
}
</script>
