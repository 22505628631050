<template>
  <v-footer color="primary">
    <v-row justify="center" no-gutters>
      <!-- Links de Texto -->
      <v-btn v-for="link in links" :key="link.text" :href="link.href" color="white" variant="text" class="mx-2"
        rounded="xl" aria-label="Ir a link.text">
        {{ link.text }}
      </v-btn>

      <v-col class="text-center mt-4" cols="12" v-if="brancagine">
        <v-btn v-for="icon in icons" :key="icon.name" :href="icon.url" target="_blank" rel="noopener" class="mx-4"
          :icon="icon.name"></v-btn>
      </v-col>

      <v-col class="text-center mt-4" cols="12">
        {{ new Date().getFullYear() }} — <strong>Simulacio.com</strong>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import { useI18n } from 'vue-i18n';
export default {
  props: {
        brancagine: String,
    },
  setup() {
    const { t } = useI18n();

    const links = [
      { text: t('Home'), href: '/' },
      { text: t('Contacto'), href: '/contacto' },
      {
        text: t('Condiciones_de_uso_y_privacidad'),
        href: '/condiciones-y-privacidad',
      },
    ];
    const icons = [
      { name: 'mdi-instagram', url: 'https://instagram.com/vallhebron.obste' },
      { name: 'mdi-youtube', url: 'https://www.youtube.com/@vallhebron.medfetal' },
    ];

    return {
      links,
      icons,
      t // exponer t para que pueda ser usado en la plantilla
    };
  },
};
</script>
