<template>
    <v-container>
        <v-form ref="form" v-model="valid">
            <v-text-field label="Título" v-model="plantilla.titulo" :rules="tituloRules" required></v-text-field>
            <v-select label="Tipo" :items="tipos" v-model="plantilla.tipo" required></v-select>
            <v-select label="Idioma" :items="idiomas" v-model="plantilla.idioma" required></v-select>
            <v-textarea label="Texto" v-model="plantilla.texto" required></v-textarea>
            <v-text-field label="Link" v-model="plantilla.link"></v-text-field>
            <v-text-field label="Título del Link" v-model="plantilla.titulo_link"></v-text-field>
            <v-btn :disabled="!valid" @click="submit">Actualitzar</v-btn>
            <v-btn color="red" @click="confirmarEliminacion">Eliminar</v-btn>
        </v-form>
        <v-alert v-if="errorMessage" type="error" dismissible @input="errorMessage = ''">
            {{ errorMessage }}
        </v-alert>
    </v-container>
</template>
  
<script>
import axios from 'axios'


export default {
    props: {
        plantillaId: {
            type: [String, Number],
            required: true
        }
    },

    data() {
        return {
            valid: true,
            errorMessage: '',
            plantilla: {
                id: '',
                titulo: '',
                tipo: '',
                idioma: '',
                texto: '',
                link: '',
                titulo_link: ''
            },
            // Reglas y opciones como en el componente de creación
        }
    },
    mounted() {
        this.cargarPlantilla()
    },
    methods: {
        async cargarPlantilla() {
            const hostname = window.location.hostname;

            try {
                const response = await axios.get(`https://${hostname}/api/plantilles/${this.plantillaId}`)
                this.plantilla = response.data
            } catch (error) {
                this.errorMessage = error.response.data.detail || 'Ha ocurrido un error';
            }
        },
        async submit() {
            const hostname = window.location.hostname;

            if (this.$refs.form.validate()) {
                try {
                    const csrfToken = this.getCookie('csrftoken');
                    const token = localStorage.getItem('authToken');
                    await axios.put(`https://${hostname}/api/plantilles/${this.plantilla.id}/`, this.plantilla, {
                        headers: {
                            'Authorization': `Token ${token}`,
                            'X-CSRFToken': csrfToken 
                        }
                    });
                    this.$emit('update-complete');
                } catch (error) {
                    this.errorMessage = error.response.data.detail || 'Ha ocurrido un error';
                }
            }
        },
        async eliminarPlantilla() {
            const hostname = window.location.hostname;

            try {
                const csrfToken = this.getCookie('csrftoken');
                const token = localStorage.getItem('authToken');
                await axios.delete(`https://${hostname}/api/plantilles/${this.plantilla.id}/`, {
                    headers: {
                        'Authorization': `Token ${token}`,
                        'X-CSRFToken': csrfToken 
                    }
                });
                this.$emit('update-complete');
            } catch (error) {
                this.errorMessage = error.response.data.detail || 'Ha ocurrido un error';
            }
        },

        confirmarEliminacion() {
            if (confirm("Estás seguro de que quieres eliminar esta plantilla?")) {
                this.eliminarPlantilla();
            }
        },
        getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
            return null;
        }
    }
}
</script>
  