<template>
    <v-btn icon="mdi-table-arrow-right" color="primary" variant="text" @click="exportToCSV">
    </v-btn>
  </template>
  
  <script setup>
  import { defineProps } from 'vue';
  
  const props = defineProps({
      dataItems: Array
  });
  
  const exportToCSV = () => {
    if (props.dataItems.length === 0) {
      console.error('No hay datos para exportar.');
      return;
    }
  
    // Obtener encabezados de las claves del primer objeto
    const headers = Object.keys(props.dataItems[0]);
  
    // Crear filas de datos
    const rows = props.dataItems.map(item =>
      headers.map(header => {
        const value = item[header];
        if (value === null || value === undefined) {
          return '';
        }
        let stringValue = value.toString();
        if (stringValue.includes(',') || stringValue.includes('"') || stringValue.includes('\n')) {
          // Escapar todas las comillas dobles dentro del valor
          stringValue = stringValue.replace(/"/g, '""');
          // Envolver el valor en comillas dobles
          stringValue = `"${stringValue}"`;
        }
        return stringValue;
      })
    );
  
    // Generar contenido CSV
    const csvContent = [
      headers.join(','),  // Encabezados
      ...rows.map(row => row.join(','))  // Datos
    ].join('\n');
  
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'export.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  </script>
  
  