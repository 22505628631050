<template>
    <v-col v-if="leccionActual.video" cols="12">
        <iframe width="100%" height="480" :src="leccionActual.video.url" title="Video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
    </v-col>
    <v-col cols="12">
        <v-card class="mx-4">
            <v-card-subtitle style="word-break: break-word;" class="mt-4 text-wrap">{{ curso.titulo }} - {{ moduls.modulos[moduloIndex].titulo
                }}</v-card-subtitle>
            <v-card-actions v-if="selectedOption === 'editar'">
                <v-btn color="primary" @click="openModuleDialog()" prepend-icon="mdi-pencil">
                    {{ t('Editar') }}
                </v-btn>
                <v-btn color="secondary" prepend-icon="mdi-sort" @click="showSortDialog = true">
                    {{ t('Ordenar') }}
                </v-btn>
                <v-btn color="error" @click="confirmDelete(leccionActual.id)" prepend-icon="mdi-delete">
                    {{ t('Eliminar') }}
                </v-btn>
            </v-card-actions>
            <v-card-title class="text-wrap" style="word-break: break-word">{{ leccionActual.titulo }}</v-card-title>
            <!-- Icono de descarga de PDF -->
            <v-btn class="ma-4" v-if="leccionActual.archivo_pdf" @click="descargarPDF(leccionActual.archivo_pdf)">
                <v-icon>mdi-file-download-outline</v-icon> {{ t('Descarga el PDF') }}
            </v-btn>
            <v-card-text>
                <MarkdownText :markdownText="leccionActual.descripcion"></MarkdownText>
            </v-card-text>
        </v-card>
    </v-col>

    <v-dialog v-model="showDialog" persistent>
        <v-card>
            <HelperCarregant v-if="loading" :missatge="t('Eliminando') + ' ' + t('leccion')" />
            <template v-if="!loading">
                <v-card-title>{{ t('Estás_seguro') }}</v-card-title>
                <v-card-text>
                    {{ t('Estás_seguro_que_quieres_eliminar') }} la lección "{{
        moduls.modulos[moduloIndex].lecciones[pendingDeleteIndex]?.titulo
    }}"?
                </v-card-text>
                <v-card-actions>
                    <v-btn color="secondary" text @click="showDialog = false">{{ t('Cancelar') }}</v-btn>
                    <v-btn color="error" text @click="eliminarModulo">{{ t('Eliminar') }}</v-btn>
                </v-card-actions>
            </template>
        </v-card>
    </v-dialog>

    <!-- Diálogo para editar leccion -->
    <v-dialog v-model="showModuleDialog" persistent>
        <v-card>
            <HelperCarregant v-if="loading" :missatge="t('Actualizando') + ' ' + t('leccion')" />
            <template v-if="!loading">
                <v-toolbar flat>
                    <v-toolbar-title>{{ t('Editar') }} {{ t('lecciones') }}</v-toolbar-title>
                    <v-btn @click="actualizarLeccion" prepend-icon="mdi-content-save">
                        {{ t('Guardar') }}
                    </v-btn>
                    <v-btn icon @click="showModuleDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-text-field v-model="leccionForm.titulo" :label="t('Título')"
                        :rules="[v => !!v || 'Campo requerido']"></v-text-field>
                    <v-row>
                        <v-col cols="12" md="6">
                            <!-- Botón para modificar video -->
                            <v-btn @click="showVideoDialog = true" color="primary">
                                {{ t('Video') }}
                            </v-btn>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-file-input v-model="leccionForm.archivo_pdf" label="Subir PDF" accept=".pdf"
                                prepend-icon="mdi-file-pdf" @change="handleFileUpload"></v-file-input>
                            <template v-if="pdfExisteix">Ja tens un PDF associat a aquesta lliçó, si en carregues un de
                                nou
                                s'eliminarà l'antic.</template>>
                        </v-col>
                    </v-row>
                    <v-textarea v-model="leccionForm.descripcion" :label="t('Descripción')"
                        :rules="[v => !!v || 'Campo requerido']" auto-grow></v-textarea>
                </v-card-text>
            </template>
        </v-card>
    </v-dialog>

    <!-- Diálogo para video -->
    <v-dialog v-model="showVideoDialog" persistent>
        <v-card>
            <HelperCarregant v-if="loading" :missatge="t('Actualizando') + ' ' + t('video')" />
            <template v-if="!loading">
                <v-toolbar color="info" dark flat>
                    <v-toolbar-title>{{ videoDialogTitle }}</v-toolbar-title>
                    <v-btn color="primary" @click="saveVideo" prepend-icon="mdi-content-save">{{ t('Guardar') }}</v-btn>
                    <v-btn v-if="leccionForm.video.id" color="error" text @click="eliminarVideo"
                        prepend-icon="mdi-delete">{{ t('Eliminar') }}</v-btn>
                    <v-btn icon @click="showVideoDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-text-field v-model="leccionForm.video.url" label="URL del Video"></v-text-field>
                    <v-text-field v-model="leccionForm.video.titulo" label="Título del Video"></v-text-field>
                </v-card-text></template>
        </v-card>
    </v-dialog>

    <v-dialog v-model="showSortDialog" persistent max-width="600px">
        <v-card>
            <HelperCarregant v-if="loading" :missatge="t('Reordenando') + ' ' + t('lecciones')" />
            <template v-if="!loading">
                <v-toolbar flat>
                    <v-toolbar-title>{{ t('Reordenar') }} {{ t('lecciones') }}</v-toolbar-title>
                    <v-btn @click="actualizarOrden" prepend-icon="mdi-content-save">
                        {{ t('Guardar') }}
                    </v-btn>
                    <v-btn icon @click="showSortDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-list dense>
                        <v-list-item v-for="(leccion, index) in moduls.modulos[moduloIndex].lecciones" :key="leccion.id">
                            <v-list-item-title>
                                {{ leccion.titulo }}
                            </v-list-item-title>
                            <template v-slot:append>
                                <v-btn icon="mdi-arrow-up" @click="moveModuleUp(index)"></v-btn>
                                <v-btn icon="mdi-arrow-down" @click="moveModuleDown(index)"></v-btn>
                            </template>
                        </v-list-item>
                    </v-list>
                </v-card-text>
            </template>
        </v-card>
    </v-dialog>
</template>



<script>
import HelperCarregant from './HelperCarregant.vue';
import { axios } from '../axios';
import MarkdownText from './MarkdownText.vue';
import { useI18n } from 'vue-i18n';

export default {
    setup() {
        const { t } = useI18n();
        return {
            t
        };
    },
    components: {
        MarkdownText,
        HelperCarregant,
    },
    emits: ['reorder-lecciones'],
    props: {
        curso: {
            type: Object,
            default: () => ({})
        },
        moduls: {
            type: Object,
            default: () => ({})
        },
        leccionActual: {
            type: Object,
            default: () => ({})
        },
        moduloIndex: Number,
        leccionIndex: Number,
        selectedOption: String,
    },
    data() {
        return {
            showDialog: false,
            pendingDeleteIndex: null,
            showModuleDialog: false,
            showVideoDialog: false,
            showSortDialog: false,
            leccionForm: {
                id: '',
                titulo: '',
                descripcion: '',
                archivo_pdf: null,
                video: {
                    id: '',
                    leccion: '',
                    url: '',
                    titulo: '',
                }
            },
            pdfExisteix: false,
            snackbar: false,
            snackbarText: '',
            snackbarColor: 'error',
            loading: false,
        };
    },
    computed: {
        videoDialogTitle() {
            return this.leccionForm.video.url ? this.t('Editar') + ' ' + this.t('video') : this.t('Añadir') + ' ' + this.t('video');
        }
    },
    methods: {
        handleDynamicLinkClick(e) {
            if (e.target.matches('.dynamic-link')) {
                e.preventDefault();
                const path = e.target.getAttribute('data-path');
                this.$router.push(path);
            }
        },
        handleFileUpload(event) {
            if (event.target.files && event.target.files.length > 0) {
                this.leccionForm.archivo_pdf = event.target.files[0]; // Asegura que estamos tomando el primer archivo
            } else {
                this.leccionForm.archivo_pdf = null; // Asegura limpiar el campo si no hay archivo
            }
        },
        descargarPDF(url) {
            window.open(url, '_blank');
        },
        async saveVideo() {
            this.loading = true;
            try {
                if (this.leccionForm.video.id) {
                    await axios.put(`videos/${this.leccionForm.video.id}/`, this.leccionForm.video);
                    this.$store.dispatch('triggerSnackbar', { message: this.t("actualizado_con_éxito"), color: 'success' });
                } else {
                    await axios.post('videos/', this.leccionForm.video);
                    this.$store.dispatch('triggerSnackbar', { message: this.t("creado_con_éxito"), color: 'success' });
                }
                const index = this.moduls.modulos[this.moduloIndex].lecciones.findIndex(m => m.id === this.leccionForm.id);
                this.$emit('update-leccion', { index, data: this.leccionForm })
                this.showVideoDialog = false;
                this.loading = false;
            } catch (error) {
                if (error.response && error.response.data && typeof error.response.data === 'object') {
                    // Extraemos los mensajes de error del cuerpo de la respuesta
                    const errors = error.response.data;
                    let errorMessage = '';
                    // Recorremos cada clave del objeto de errores para construir el mensaje
                    Object.keys(errors).forEach(key => {
                        errorMessage += `${key}: ${errors[key].join(' ')}\n`; // Join para convertir posibles arrays en strings
                    });
                    this.snackbarText = errorMessage;
                } else {
                    // Si la respuesta de error no es en el formato esperado
                    this.snackbarText = error;
                }
                this.$store.dispatch('triggerSnackbar', { message: this.snackbarText, color: 'error' });
                this.loading = false;
            }
        },
        async actualizarLeccion() {
            this.loading = true;
            const formData = new FormData();
            Object.keys(this.leccionForm).forEach(key => {
                const value = this.leccionForm[key];
                // Comprueba si el valor no es null antes de agregarlo al FormData
                if (value !== null && value !== undefined) {
                    if (key === 'archivo_pdf' && value instanceof File) {
                        formData.append(key, value);
                    } else {
                        formData.append(key, value);
                    }
                }
            });
            try {
                const response = await axios.put(`lecciones/${this.leccionForm.id}/`, formData);
                const index = this.moduls.modulos[this.moduloIndex].lecciones.findIndex(m => m.id === this.leccionForm.id);
                this.$emit('update-leccion', { index, data: response.data });
                this.showModuleDialog = false;
                this.loading = false;
                this.snackbarText = response.data.titulo + ' ' + this.t("fue_actualizado_con_éxito");
                this.$store.dispatch('triggerSnackbar', { message: this.snackbarText, color: 'success' });
            } catch (error) {
                if (error.response && error.response.data && typeof error.response.data === 'object') {
                    // Extraemos los mensajes de error del cuerpo de la respuesta
                    const errors = error.response.data;
                    let errorMessage = '';
                    // Recorremos cada clave del objeto de errores para construir el mensaje
                    Object.keys(errors).forEach(key => {
                        errorMessage += `${key}: ${errors[key].join(' ')}\n`; // Join para convertir posibles arrays en strings
                    });
                    this.snackbarText = errorMessage;
                } else {
                    // Si la respuesta de error no es en el formato esperado
                    this.snackbarText = error;
                }
                this.loading = false;
                this.$store.dispatch('triggerSnackbar', { message: this.snackbarText, color: 'error' });
            }
        },
        confirmDelete() {
            this.showDialog = true;
        },
        async eliminarModulo() {
            this.loading = true;
            const leccionId = this.leccionActual.id;
            try {
                await axios.delete(`lecciones/${leccionId}/`);
                this.$emit('delete-leccion', this.pendingDeleteIndex);
                this.showDialog = false;  // Cierra el diálogo tras la eliminación
                this.snackbarText = this.t("La_leccion") + ' ' + this.t("se_ha_eliminado_con_éxito");
                this.loading = false;
                this.$store.dispatch('triggerSnackbar', { message: this.snackbarText, color: 'success' });
            } catch (error) {
                this.snackbarText = error;
                this.loading = false;
                this.$store.dispatch('triggerSnackbar', { message: this.snackbarText, color: 'error' });
            }
        },
        async eliminarVideo() {
            this.loading = true;
            const videoId = this.leccionForm.video.id;
            try {
                await axios.delete(`videos/${videoId}/`);
                this.showDialog = false;  // Cierra el diálogo tras la eliminación
                this.snackbarText = this.t("se_ha_eliminado_con_éxito");
                this.loading = false;
                this.$store.dispatch('triggerSnackbar', { message: this.snackbarText, color: 'success' });
            } catch (error) {
                this.snackbarText = error;
                this.loading = false;
                this.$store.dispatch('triggerSnackbar', { message: this.snackbarText, color: 'error' });
            }
        },
        openModuleDialog() {
            // Copia la información del módulo existente para editar
            this.leccionForm.titulo = this.leccionActual.titulo;
            this.leccionForm.descripcion = this.leccionActual.descripcion;
            this.leccionForm.id = this.leccionActual.id;
            this.leccionForm.modulo = this.leccionActual.modulo;
            if (this.leccionActual.archivo_pdf) {
                this.pdfExisteix = true
            } else {
                this.pdfExisteix = false
            }
            this.leccionForm.archivo_pdf = this.leccionActual.archivo_pdf
            if (!this.leccionActual.video || !this.leccionActual.video.url) {
                this.leccionForm.video = { leccion: this.leccionActual.id, url: '', titulo: '' };
            } else {
                this.leccionForm.video = { leccion: this.leccionActual.id, id: this.leccionActual.video.id, url: this.leccionActual.video.url, titulo: this.leccionActual.video.titulo };
            }
            this.showModuleDialog = true;
        },
        moveModuleUp(index) {
            if (index > 0) {
                this.$emit('reorder-lecciones', { type: 'up', index: index });
            }
        },
        moveModuleDown(index) {
            if (index < this.moduls.modulos[this.moduloIndex].lecciones.length - 1) {
                this.$emit('reorder-lecciones', { type: 'down', index: index });
            }
        },
        async actualizarOrden() {
            this.loading = true;
            const orderedModuleIds = this.moduls.modulos[this.moduloIndex].lecciones.map(leccion => leccion.id);
            try {
                await axios.put('lecciones/reorder/', {
                    curso_id: this.curso.id,
                    modulo: this.moduls.modulos[this.moduloIndex].id,
                    new_order: orderedModuleIds
                });
                this.snackbarText = this.t("Orden_guardado_con_éxito");
                this.loading = false;
                this.$store.dispatch('triggerSnackbar', { message: this.snackbarText, color: 'success' });
            } catch (error) {
                this.snackbarText = error;
                this.loading = false;
                this.$store.dispatch('triggerSnackbar', { message: this.snackbarText, color: 'error' });
            }
            this.showSortDialog = false;
        },
    },
};
</script>