<template>
    <v-container>
        <v-list lines="two">
            <v-list-item :title="titol">
                <template v-if="boto" v-slot:append>
                    <v-btn prepend-icon="mdi-plus" @click="emit('open-dialog')" color="primary">{{ t(boto) }}</v-btn>
                </template>
            </v-list-item>

            <v-divider></v-divider>

            <!-- Verifica si hay cursos para mostrar -->
            <template v-if="cursos.length > 0">
                <v-list-item v-for="curso in cursos" :key="curso.id" :to="`${url}${curso.id}`"
                    :prepend-avatar="curso.imagen_url">
                    <v-list-item-title>{{ curso.titulo }}</v-list-item-title>
                    <v-list-item-subtitle>{{ curso.descripcion }}</v-list-item-subtitle>
                    <template v-slot:append v-if="mostrarAppend">
                        <v-icon v-if="curso.notas && curso.notas.nota_final" size="x-large"
                            :icon="getIconData(curso.notas.nota_final).icon"
                            :color="getIconData(curso.notas.nota_final).color">
                        </v-icon>
                        <template v-if="curso.estado_acceso_usuario.acceso_docente">
                            <v-tooltip :text="t('Vista alumno')" location="top">
                                <template v-slot:activator="{ props }">
                                    <v-btn v-bind="props" variant="text" icon="mdi-eye" @click="setVista('alumno')"></v-btn>
                                </template>
                            </v-tooltip>
                            <v-tooltip :text="t('Vista docente')" location="top">
                                <template v-slot:activator="{ props }">
                                    <v-btn v-bind="props" variant="text" icon="mdi-account-multiple-check" @click="setVista('docente')"></v-btn>
                                </template>
                            </v-tooltip>
                        </template>
                    </template>
                </v-list-item>
            </template>

            <!-- Mensaje cuando no hay cursos -->
            <v-list-item v-else>
                <v-row justify="center">
                    <v-col justify="center" align-self="center">
                        <v-progress-circular indeterminate color="primary" v-if="isLoading"></v-progress-circular>
                    </v-col>
                </v-row>

                <v-list-item-subtitle v-if="!isLoading">{{ error }}</v-list-item-subtitle>
            </v-list-item>
        </v-list>
    </v-container>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

defineProps({
    mostrarAppend: {
        type: Boolean,
        default: true
    },
    isLoading: {
        type: Boolean,
        default: true
    },
    cursos: {
        type: Array,
        default: () => []
    },
    error: String,
    titol: String,
    url: String,
    boto: String
});

const { t } = useI18n();
const emit = defineEmits(['open-dialog']);
const store = useStore();

function setVista(vista) {
    store.dispatch('setSelectedOptionVista', vista);
}

function getIconData(nota) {
    if (nota >= 9) {
        return { icon: 'mdi-star', color: 'yellow-darken-1' }; // O el color específico que desees para 'dorado'
    } else if (nota >= 7) {
        return { icon: 'mdi-star', color: 'blue-grey-lighten-4' }; // O el color específico que desees para 'plata'
    } else if (nota >= 5) {
        return { icon: 'mdi-star', color: 'deep-orange-darken-3' }; // O el color específico que desees para 'bronce'
    } else {
        return { icon: 'mdi-emoticon-sad', color: 'red' }; // Icono de carita triste para notas menores de 5
    }
}
</script>
