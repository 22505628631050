<template>
    <v-container>
        <v-row class="d-flex justify-center" align="center">
            <v-col cols="12" md="10">
                <v-card class="ma-5" variant="text">
                    <v-card-title>{{ title }}</v-card-title>
                    <v-card-text>{{ t(description) }}</v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { useI18n } from 'vue-i18n';
export default {
    props: {
        title: String,
        description: String,
    },
    setup() {
        const { t } = useI18n();
        return {
            t // exponer t para que pueda ser usado en la plantilla
        };
    },
};
</script>