<template>
    <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="6">
                <v-card>
                    <v-card-title>{{ t('Confirmación_de_Correo_Electrónico') }}</v-card-title>
                    <v-card-text>
                        <HelperCarregant v-if="loading" :missatge="t('Confirmando_correo_electrónico')" />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { axios } from '../axios';
import { useI18n } from 'vue-i18n';
import HelperCarregant from './HelperCarregant.vue';

export default {
    components: {
        HelperCarregant,
    },
    setup() {
        const { t } = useI18n();
        return {
            t
        };
    },
    data() {
        return {
            loading: true,
        };
    },
    computed: {
        key() {
            return this.$route.params.key;
        },
    },
    methods: {
        async confirmEmail() {
            try {
                await axios.post(`dj-rest-auth/registration/account-confirm-email/${this.key}/`, {
                    key: this.key
                }, {
                });
                this.$store.dispatch('triggerSnackbar', { message: this.t("Correo_electrónico_confirmado_con_éxito"), color: 'success' });
                this.$router.push('/login');
            } catch (error) {
                console.log(error);
                this.$store.dispatch('triggerSnackbar', { message: 'Error al confirmar el correo electrónico. Puede que el enlace haya caducado o sea inválido.', color: 'success' });
                this.$router.push('/login');
            }
        },
    },
    created() {
        this.confirmEmail();
    }
}
</script>