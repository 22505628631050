<template>
    <v-card variant="text">
        <v-card-title>{{ t('Simuladores') }}
            <v-btn fab small color="primary" @click="newSimulador" class="ma-2">
                <v-icon>mdi-plus</v-icon>
            </v-btn>

        </v-card-title>
        <v-text-field class="mx-4 mt-4" v-model="search" label="Cerca" prepend-inner-icon="mdi-magnify" single-line
            variant="outlined" hide-details></v-text-field>
        <v-data-table-virtual :headers="headers" :items="simuladores" :search="search" :loading="loading">
            <template v-slot:[`item.action`]="{ item }">
                <v-icon small @click="editSimulador(item)">mdi-pencil</v-icon>
            </template>
            <template v-slot:loading>
                <v-skeleton-loader type="table-row@10"></v-skeleton-loader>
            </template>
        </v-data-table-virtual>
    </v-card>
    <v-dialog v-model="editDialog" persistent max-width="600px">
        <v-card>
            <v-card-title>
                {{ editedSimulador.id ? t('Editar') : t('Crear') }} {{ t('simulador') }}
            </v-card-title>

            <v-card-text>
                <v-text-field v-model="editedSimulador.nombre" :label="t('Nombre')"></v-text-field>
                <v-text-field v-model="editedSimulador.descripcion" :label="t('Descripción')"></v-text-field>
                <v-text-field v-model="editedSimulador.num_alumnos" :label="t('Número_de_alumnos')"
                    type="number"></v-text-field>
                <v-text-field v-model="editedSimulador.num_docentes" :label="t('Número_de_docentes')"
                    type="number"></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="close">{{ t('Cancelar') }}</v-btn>
                <v-btn text @click="save">{{ t('Guardar') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
import { axios } from '../axios';
import { useI18n } from 'vue-i18n';
export default {
    setup() {
        const { t } = useI18n();
        return {
            t
        };
    },
    data() {
        return {
            headers: [
                { title: this.t('Nombre'), key: 'nombre' },
                { title: this.t('Descripción'), key: 'descripcion' },
                { title: this.t('Número_de_alumnos'), key: 'num_alumnos' },
                { title: this.t('Número_de_docentes'), key: 'num_docentes' },
                { title: this.t('Acciones'), key: 'action', sortable: false },
            ],
            simuladores: [],
            editDialog: false,
            editedSimulador: {},
            search: '',
            loading: false,
        }
    },
    methods: {
        async fetchSimuladores() {
            this.loading = true
            try {
                const response = await axios.get('simuladores/'); // Asegúrate de que la ruta coincide con tu configuración en Django
                this.simuladores = response.data;
            } catch (error) {
                console.error('There was an error fetching simuladores:', error);
                // Aquí podrías manejar el error, por ejemplo, mostrando un mensaje al usuario
            }
            this.loading = false
        },
        editSimulador(simulador) {
            this.editedSimulador = Object.assign({}, simulador);
            this.editDialog = true;
        },
        newSimulador() {
            this.editedSimulador = { nombre: '', descripcion: '', num_alumnos: 0, num_docentes: 0 }; // Asegúrate de que todos los campos necesarios estén incluidos
            this.editDialog = true;
        },
        async save() {
            try {
                const method = this.editedSimulador.id ? 'put' : 'post';
                const url = this.editedSimulador.id ? `simuladores/${this.editedSimulador.id}/` : 'simuladores/';
                await axios[method](url, this.editedSimulador);
                this.fetchSimuladores();  // Recargar lista de simuladores tras guardar
                this.editDialog = false;
            } catch (error) {
                console.error('There was an error saving the simulador:', error);
                // Manejar errores aquí
            }
        },
        close() {
            this.editDialog = false;
        }
    },
    mounted() {
        this.fetchSimuladores();
    },
}
</script>