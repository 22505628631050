
<template>
    <v-container>
        <v-row class="mb-6" v-for="(location, index) in locations" :key="index">
            <!-- Sección del mapa -->
            <v-col cols="12" md="6">
                <v-card>
                    <v-card-title>{{ location.title }}</v-card-title>
                    <v-card-text>
                        <!-- Incrusta el mapa de Google usando iframe -->
                        <iframe width="100%" height="300" frameborder="0" style="border:0" :src="location.mapLink"
                            allowfullscreen></iframe>
                    </v-card-text>
                </v-card>
            </v-col>

            <!-- Sección de información de contacto -->
            <v-col cols="12" md="6">
                <v-card>
                    <v-card-title>{{ t('Información_de_contacto') }}</v-card-title>
                    <v-card-text>
                        <p><strong>{{ t('Dirección') }}:</strong> {{ location.address }}</p>
                        <p><strong>{{ t('Email') }}:</strong> {{ location.email }}</p>
                        <p><strong>{{ t('Teléfono') }}:</strong> {{ location.phone }}</p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { useI18n } from 'vue-i18n';
export default {
    setup() {
        const { t } = useI18n();
        return {
            t
        };
    },
    data() {
        return {
            locations: [
                {
                    title: 'Hospital de la Dona',
                    mapLink: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11965.913674909416!2d2.1430921!3d41.4288397!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4067bc2c7cf95849%3A0xebdbe44003e4a0ec!2sHospital%20Infantil%20i%20Hospital%20de%20la%20Dona!5e0!3m2!1sca!2ses!4v1698101533849!5m2!1sca!2ses',
                    address: "Edifici Hospital de la Dona. Vall d'Hebron Barcelona Hospital Campus. Pg. de la Vall d'Hebron, 119-129, 08035 Barcelona",
                    email: 'cursosmedfetalvh@gmail.com',
                    phone: '93 489 30 83'
                },
                {
                    title: 'Centre de Simulació Clínica Avançada',
                    mapLink: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1778.7919757721104!2d2.1383805910003755!3d41.42707798758544!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a4973f664d507d%3A0x9cb0364ed9996601!2sCentre%20de%20Simulaci%C3%B3%20Cl%C3%ADnica%20Avan%C3%A7ada!5e0!3m2!1sca!2ses!4v1698101826981!5m2!1sca!2ses',
                    address: "Edifici de Traumatologia, 5a Planta. Vall d'Hebron Barcelona Hospital Campus. Pg. de la Vall d'Hebron, 119-129, 08035 Barcelona",
                    email: 'simulacio@vallhebron.cat',
                    phone: 'ext. 2455'
                }
            ]
        };
    }
};
</script>
  