// main.js
import { createApp } from 'vue';
import App from './App.vue';
import store from './store'; 
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
import router from './router';
import i18n from './i18n'; // Importar la instancia i18n configurada

loadFonts();

const app = createApp(App);

app.use(store);
app.use(router);
app.use(vuetify);
app.use(i18n); // Usar la instancia i18n
app.mount('#app');
