<template>
    <v-card variant="text">
        <v-toolbar flat>
            <v-toolbar-title>{{ t('Usuarios') }}</v-toolbar-title>
            <ExportExcel :dataItems="users" />
        </v-toolbar>
        <v-text-field class="mx-4 mt-4" v-model="search" label="Cerca" prepend-inner-icon="mdi-magnify" single-line
            variant="outlined" hide-details></v-text-field>
        <v-data-table-virtual :headers="headers" :items="users" :search="search" :loading="loading">
            <template v-slot:[`item.action`]="{ item }">
                <v-icon small @click="editUser(item)">mdi-pencil</v-icon>
            </template>
            <template v-slot:loading>
                <v-skeleton-loader type="table-row@10"></v-skeleton-loader>
            </template>
        </v-data-table-virtual>
    </v-card>
    <v-dialog v-model="editDialog" persistent max-width="600px">
        <v-card>
            <v-card-title>
                <span class="text-h5">{{ t('Editar') }} {{ t('usuario') }}</span>
            </v-card-title>
            <v-card-text>
                <v-text-field v-model="editedUser.first_name" :label="t('Nombre')"></v-text-field>

                <v-text-field v-model="editedUser.last_name" :label="t('Apellidos')"></v-text-field>

                <v-text-field v-model="editedUser.fecha_inicio_especialidad" :label="t('Fecha_inicio_especialidad')"
                    type="date" required></v-text-field>
                <v-autocomplete v-model="editedUser.profesional" :items="tiposProfesionales" item-title="categoria"
                    item-value="id" :label="t('Profesional')"></v-autocomplete>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">{{ t('Cancelar') }}</v-btn>
                <v-btn color="blue darken-1" text @click="save">{{ t('Guardar') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
import { axios } from '../axios';
import { useI18n } from 'vue-i18n';
import ExportExcel from './ExportExcel.vue';
export default {
    setup() {
        const { t } = useI18n();
        return {
            t
        };
    },
    components: {
        ExportExcel,
    },
    data() {
        return {
            headers: [
                { title: this.t('Email'), key: 'email' },
                { title: this.t('Vallhebron'), key: 'vallhebron' },
                { title: this.t('Nombre'), key: 'first_name' },
                { title: this.t('Apellidos'), key: 'last_name' },
                { title: this.t('Seudónimo'), key: 'nickname' },
                { title: this.t('Año_de_residencia'), key: 'experiencia' },
                { title: this.t('Profesional'), key: 'catprofesional' },
                { title: this.t('Teléfono'), key: 'telefono' },
                { title: this.t('DNI'), key: 'dni' },
                { title: this.t('Admin'), key: 'is_admin' },
                { title: this.t('Acciones'), key: 'action', sortable: false },
            ],
            users: [],
            editDialog: false,
            editedUser: {},
            search: '',
            loading: true,
        }
    },
    methods: {
        async fetchProfesionales() {
            try {
                const response = await axios.get(`profesionales/`, {
                });

                this.tiposProfesionales = response.data.sort((a, b) => a.categoria.localeCompare(b.categoria));
            } catch (error) {
                console.error("Error al obtener las categorías de profesionales:", error);
            }
        },
        async fetchUsers() {
            this.loading = true
            try {
                const response = await axios.get('users/'); // Asegúrate de que la ruta coincide con tu configuración en Django
                this.users = response.data;
            } catch (error) {
                console.error('There was an error fetching the users:', error);
                // Aquí podrías manejar el error, por ejemplo, mostrando un mensaje al usuario
            }
            this.loading = false
        },
        editUser(user) {
            this.editedUser = Object.assign({}, user);
            this.editDialog = true;
        },
        async save() {
            try {
                await axios.put(`users/${this.editedUser.id}/`, this.editedUser);
                this.fetchUsers();  // Recargar lista de usuarios tras la actualización
                this.editDialog = false;
            } catch (error) {
                console.error('There was an error updating the user:', error);
                // Manejar errores de actualización aquí, por ejemplo, notificaciones al usuario
            }
        },
        close() {
            this.editDialog = false;
        }
    },
    mounted() {
        this.fetchProfesionales();
        this.fetchUsers();
    },
}
</script>