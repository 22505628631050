<template>
  <v-container>
    <PresentationPage v-if="!isLoggedIn" />
    <HomeUser v-else />
  </v-container>
</template>


<script>
import PresentationPage from './PresentationPage.vue';
import HomeUser from './HomeUser.vue';

export default {
  name: 'HomePage',
  components: {
    PresentationPage,
    HomeUser,
  },
  props: {
    isLoggedIn: {
      type: Boolean,
      default: false
    }
  }
}
</script>