<template>
  <v-card variant="text">
    <v-card-title class="text-wrap" style="word-break: break-word">
      {{ pregunta.texto }}
    </v-card-title>
    <v-card-text>
      <v-row no-gutters>
        <v-col cols="6" v-for="group in groupedOptions" :key="group.nombre">
          <h3>{{ group.nombre }}</h3>
          <draggable v-model="group.opciones" group="shared" :animation="300">
            <template #item="{ element: item }">
              <v-list>
                <v-list-item :base-color="getColor(item)">{{ item.texto }}</v-list-item>
              </v-list>
            </template>
          </draggable>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
  <v-btn block v-if="!haRespondido" :disabled="loading" color="primary" class="mt-3" @click="enviarCuestionario">
    {{ t('Comprueba') }}
  </v-btn>
  <v-btn block v-if="haRespondido" :disabled="loading" color="primary" class="mt-3" @click="next">
    {{ t('Continua') }}
  </v-btn>
</template>

<script setup>
import { ref, watch } from 'vue';
import draggable from 'vuedraggable';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  pregunta: {
    type: Object,
    default: () => ({})
  }
});
const { t } = useI18n();
const emits = defineEmits(['respuesta-enviada']);

const loading = ref(false);
const haRespondido = ref(false);

const groupedOptions = ref([]);

// Función para calcular las opciones agrupadas
function calculateGroupedOptions(options) {
  const nombres = Array.from(new Set(options.map(opcion => opcion.texto2)));
  const groupSize = Math.ceil(options.length / nombres.length);
  const grupos = [];
  nombres.forEach((nombre, index) => {
    const start = index * groupSize;
    const end = start + groupSize;
    grupos.push({
      nombre: nombre,
      opciones: options.slice(start, end)
    });
  });
  groupedOptions.value = grupos;
}

// Watcher que observa cambios en props.pregunta.opciones
watch(() => props.pregunta.opciones, (newOptions) => {
  if (newOptions) {
    calculateGroupedOptions(newOptions);
  }
}, { deep: true });

async function enviarCuestionario() {
  loading.value = true;
  haRespondido.value = true;

  // Comprobar si todos los items están en el grupo correcto
  groupedOptions.value.forEach(group => {
    group.opciones.forEach(item => {
      if (item.texto2 === group.nombre) {
        item.correct = true; // Correcto
      } else {
        item.correct = false; // Incorrecto
      }
    });
  });


  loading.value = false;
}

function getColor(item) {
  if (!haRespondido.value) return 'primary'; // Default color before response
  return item.correct ? 'green' : 'red';
}

function next() {
  emits('respuesta-enviada', props.pregunta);
}

// Calcular inicialmente al montar el componente
calculateGroupedOptions(props.pregunta.opciones);
</script>