<template>
    <v-container>
        <v-card class="mx-4 mt-4">
            <v-btn class="mx-4 mt-4" :disabled="!isLoggedIn" color="primary" @click="mostrarFormularioCreacion">Crear
                Plantilla</v-btn>
            <v-chip v-if="!isLoggedIn">
                Per crear una nova plantilla cal que inicis sessió.
            </v-chip>

            <v-text-field class="mx-4 mt-4" v-model="search" label="Cerca" prepend-inner-icon="mdi-magnify" single-line
                variant="outlined" hide-details></v-text-field>
            <v-row>
                <v-col cols="12" md="6" v-for="(grupo, tipo) in plantillasPorTipo" :key="tipo">
                    <v-card class="ma-4">
                        <v-card-title>{{ tipo }}</v-card-title>
                        <v-data-table-virtual :headers="headers" :items="grupo" :search="search" class="elevation-1">
                            <!-- Slot personalizado para 'link' -->
                            <template v-slot:[`item.link`]="{ item }">
                                <a :href="item.link" target="_blank">{{ item.titulo_link }}</a>
                            </template>
                            <template v-slot:[`item.acciones`]="{ item }">
                                <v-icon @click="mostrarDetalles(item)">mdi-eye</v-icon> <span> </span>
                                <v-icon v-if="isLoggedIn" small @click="editarPlantilla(item)">mdi-pencil</v-icon>
                            </template>
                        </v-data-table-virtual>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="ma-4">
                    <p class="font-italic text-caption">Atenció: El contingut d'aquestes plantilles no ha estat revisat ni aprovat per
                        Simulacio.com.
                        La informació proporcionada aquí s'ofereix tal com està, sense cap garantia de precisió, vigència o
                        aplicabilitat a casos específics. Simulacio.com no es fa responsable de cap conseqüència, dany o perjudici
                        que
                        pugui resultar de l'ús d'aquesta informació.
                    </p>
                </v-col>
            </v-row>
        </v-card>

        <v-dialog v-model="dialogDetalles" fullscreen>
            <v-card>
                <v-card-title>{{ detallePlantilla.titulo }} ({{ detallePlantilla.idioma }})</v-card-title>
                <v-card-text style="overflow-y: auto;">
                    <pre v-html="formatText(detallePlantilla.texto)"
                        style="white-space: pre-wrap; word-wrap: break-word;"></pre>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="blue darken-1" text @click="dialogDetalles = false">{{ t('Cerrar') }}</v-btn>
                    <v-spacer></v-spacer>
                    Plantilla creada per {{ detallePlantilla.autor.nickname }}
                </v-card-actions>
            </v-card>
        </v-dialog>


        <v-dialog persistent v-model="dialog" max-width="500px">
            <v-card>
                <UtilsCrearPlantilla v-if="isCreating" @update-complete="handleUpdateComplete" />
                <UtilsEditarPlantilla v-if="isEditing" :plantillaId="editingId" @update-complete="handleUpdateComplete" />
                <v-card-actions>
                    <v-btn color="blue-darken-1" variant="text" @click="dialog = false">
                        {{ t('Cerrar') }}
                    </v-btn> </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

  
<script>
import axios from 'axios'
import { useI18n } from 'vue-i18n';
import UtilsCrearPlantilla from './UtilsCrearPlantilla.vue'
import UtilsEditarPlantilla from './UtilsEditarPlantilla.vue'

export default {
    setup() {
        const { t } = useI18n();
        return {
            t
        };
    },
    components: {
        UtilsCrearPlantilla,
        UtilsEditarPlantilla
    },
    data() {
        return {
            plantillas: [],
            search: '',
            isLoggedIn: false,
            dialog: false,
            isCreating: false,
            isEditing: false,
            editingId: null,
            dialogDetalles: false,
            detallePlantilla: {},
            headers: [
                { title: 'Títol', align: 'start', key: 'titulo' },
                { title: 'Idioma', key: 'idioma' },
                { title: 'Link', key: 'link' },
                { title: 'Plantilla', key: 'acciones', sortable: false } // Para botones de editar/eliminar
            ],

        }
    },
    mounted() {
        this.cargarPlantillas()
    },
    computed: {
        plantillasPorTipo() {
            const grupos = {};
            this.plantillas.forEach((plantilla) => {
                if (!grupos[plantilla.tipo]) {
                    grupos[plantilla.tipo] = [];
                }
                grupos[plantilla.tipo].push(plantilla);
            });
            return grupos;
        }
    },
    created() {
        // Verifica si el usuario está logueado cuando el componente es creado
        this.isLoggedIn = !!localStorage.getItem('authToken');
    },
    methods: {
        async cargarPlantillas() {
            const hostname = window.location.hostname;

            try {
                const response = await axios.get(`https://${hostname}/api/plantilles/`)
                this.plantillas = response.data
            } catch (error) {
                console.error(error)
            }
        },
        editarPlantilla(plantilla) {
            this.editingId = plantilla.id;
            this.isEditing = true;
            this.isCreating = false;
            this.dialog = true;
        },
        handleUpdateComplete() {
            this.dialog = false;
            this.isCreating = false;
            this.isEditing = false;
            this.editingId = null;
            this.cargarPlantillas(); // Recargar la lista después de crear/editar
        },
        mostrarFormularioCreacion() {
            this.isCreating = true;
            this.isEditing = false;
            this.dialog = true;
        },
        mostrarDetalles(plantilla) {
            this.detallePlantilla = plantilla;
            this.dialogDetalles = true;
        },
        formatText(text) {
            // Reemplaza los marcadores por etiquetas <b>
            return text.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');
        },
    }
}
</script>
  
