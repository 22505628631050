<template>
    <v-card variant="text">
        <v-toolbar flat>
            <v-toolbar-title>{{ t('Actividades') }}</v-toolbar-title>
            <ExportExcel :dataItems="cursos" />
        </v-toolbar>
        <v-text-field class="mx-4 mt-4" v-model="search" label="Cerca" prepend-inner-icon="mdi-magnify" single-line
            variant="outlined" hide-details></v-text-field>
        <v-data-table-virtual :headers="headers" :items="cursos" :search="search" @click:row="editCurso"
            :loading="loading">
            <template v-slot:loading>
                <v-skeleton-loader type="table-row@10"></v-skeleton-loader>
            </template>
        </v-data-table-virtual>
    </v-card>
    <v-dialog v-model="editDialog" persistent>
        <v-card>
            <v-card-title>{{ t('Editar') }} {{ t('actividad') }}: {{ editedCurso.titulo }} </v-card-title>
            <v-card-text>
                <v-text-field v-model="editedCurso.afos" label="AFOS"></v-text-field>
                <v-autocomplete multiple v-model="editedCurso.simuladores" :items="tiposSimuladores" item-title="nombre"
                    item-value="id" :label="t('Simuladores')"></v-autocomplete>
                <v-textarea v-model="emailsRaw"
                    label="Pega los emails de las personas a las que quieras conceder acceso."
                    hint="Puedes copiar y pegar los emails seleccionandolos todos a la vez en una columna de Excel."
                    persistent-hint auto-grow :rows="3"></v-textarea>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green" text :to="`/curso/${editedCurso.id}`">{{ t('Contenido de la actividad') }}</v-btn>
                <v-btn color="blue darken-1" text @click="close">{{ t('Cancelar') }}</v-btn>
                <v-btn color="blue darken-1" text @click="save">{{ t('Guardar') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="5000">{{ snackbarText }}</v-snackbar>
</template>


<script>
import { axios } from '../axios';
import { useI18n } from 'vue-i18n';
import ExportExcel from './ExportExcel.vue';

export default {
    setup() {
        const { t } = useI18n();
        return {
            t
        };
    },
    components: {
        ExportExcel,
    },
    data() {
        return {
            headers: [
                { title: this.t('AFOS'), key: 'afos' },
                { title: this.t('Título'), key: 'titulo' },
                { title: this.t('Descripción'), key: 'descripcion' },
                { title: this.t('Director'), key: 'nombre_director' },
                { title: this.t('Fecha_de_inicio'), key: 'fecha_inicio' },
                { title: this.t('Fecha_de_fin'), key: 'fecha_fin' },
                { title: this.t('Acceso'), key: 'estado_curso' },
                { title: this.t('Externalizar'), key: 'externalizar' },
                { title: this.t('Año_de_residencia'), key: 'experiencia_minima' },
                { title: this.t('Profesionales'), key: 'profesionales' },
                { title: this.t('Simuladores'), key: 'infosimuladores' },
            ],
            cursos: [],
            tiposSimuladores: [],
            editDialog: false,
            editedCurso: {},
            search: '',
            snackbar: false,
            snackbarText: '',
            snackbarColor: 'error',
            loading: 'true',
            emailsRaw: '',
        }
    },
    watch: {
        emailsRaw(newVal) {
            this.processPastedEmails(newVal);
        }
    },
    methods: {
        async fetchSimuladores() {
            try {
                const response = await axios.get('simuladores/'); // Asegúrate de que la ruta coincide con tu configuración en Django
                this.tiposSimuladores = response.data;
            } catch (error) {
                console.error('There was an error fetching simuladores:', error);
                // Aquí podrías manejar el error, por ejemplo, mostrando un mensaje al usuario
            }
        },

        async fetchCursos() {
            this.loading = true
            try {
                const response = await axios.get('admincursos/'); // Asegúrate de que la ruta coincide con tu configuración en Django
                this.cursos = response.data.map(curso => ({
                    ...curso,
                    profesionales: curso.profesionales?.length > 0
                        ? curso.profesionales.map(p => p.categoria).join(', ')
                        : '',
                    infosimuladores: curso.infosimuladores?.length > 0
                        ? curso.infosimuladores.map(p => p.nombre).join(', ')
                        : '',
                }));
            } catch (error) {
                console.error('There was an error fetching cursos:', error);
                // Aquí podrías manejar el error, por ejemplo, mostrando un mensaje al usuario
            }
            this.loading = false
        },
        editCurso(e, { item }) {
            console.log(item)
            this.editedCurso = Object.assign({}, item);
            this.emailsRaw = this.editedCurso.admitidos.join('\n');
            this.editDialog = true;
        },

        async save() {
            const formData = new FormData();
            Object.keys(this.editedCurso).forEach(key => {
                const value = this.editedCurso[key];
                // Comprueba si el valor no es null antes de agregarlo al FormData
                if (value !== null && value !== undefined) {
                    if (key === 'admitidos' && typeof value === 'object') {
                        formData.append(key, JSON.stringify(value)); // Convertir el objeto a JSON
                    } else if (Array.isArray(value)) {
                        value.forEach(item => {
                            formData.append(key, item);
                        });
                    } else {
                        formData.append(key, value);
                    }
                }
            });
            try {
                const response = await axios.put(`admincursos/${this.editedCurso.id}/`, formData);
                this.editDialog = false;
                this.snackbarText = response.data.titulo + ' ' + 'fue actualizado con éxito';
                this.snackbarColor = 'success';
                this.fetchCursos();
            } catch (error) {
                if (error.response) {
                    if (error.response.data.non_field_errors) {
                        this.snackbarText = 'Error: ' + error.response.data.non_field_errors.join(', ');
                    }
                    else if (error.response.data.detail) {
                        this.snackbarText = 'Error: ' + error.response.data.detail;
                    }
                    else {
                        this.snackbarText = '';
                        Object.keys(error.response.data).forEach(key => {
                            this.snackbarText += `${key}: ${error.response.data[key].join(', ')}; `;
                        });
                    }
                }
                this.snackbarColor = 'error';
            }
            this.snackbar = true;
        },
        close() {
            this.editDialog = false;
        },
        processPastedEmails(emailsString) {
            const emails = emailsString.split(/\r?\n/); // Separa por saltos de línea
            this.editedCurso.admitidos = emails.map(email => email.trim()).filter(email => email);
        },
    },
    mounted() {
        this.fetchCursos();
        this.fetchSimuladores();
    },
}
</script>