<template>
  <v-container class="fill-height">
    <v-row no-gutters>
      <v-col cols="12" sm="6" class="pa-6">
        <!-- Capçalera i Introducció -->
        <v-row>
          <v-col cols="12">
            <h1>{{
              t('Inspira') }}</h1>
            <p>{{
              t('GineSIM_inspira_descripcion') }}</p>
          </v-col>
        </v-row>

        <!-- Secció de Participació Com a Docent -->
        <v-row>
          <v-col cols="12">
            <h2>{{
              t('Participa_Como_Docente') }}</h2>
            <p>{{
              t('Participa_Como_Docente_descripcion') }}</p>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="6">
        <LlistaActivitats :cursos="cursosdirigidos" :error="t('No_has_creado_ninguna_actividad')" :titol="titol_teves"
          :url="url" :mostrarAppend="true" :boto="boto" @open-dialog="openDialog" :isLoading="isLoadingDirigidos">
        </LlistaActivitats>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <LlistaActivitats :cursos="cursos" :error="t('No_eres_docente_de_ninguna_actividad')" :titol="titol" :url="url"
          :mostrarAppend="true" :isLoading="isLoadingCursos">
        </LlistaActivitats>
        <v-col v-if="error" class="text-center">
          <p>{{ error }}</p>
          <v-btn class="ma-4" color="primary" :to="`/perfil`">{{
              t('Accede_a_tu_perfil') }}</v-btn>
        </v-col>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" persistent>
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>{{ t('Crear') }} {{ t('actividad') }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="handleSubmit" prepend-icon="mdi-content-save">
            {{ t('Guardar') }}
          </v-btn>
          <v-btn icon @click="tancarDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-expansion-panels v-model="panel" multiple>
              <v-expansion-panel :title="t('Características_generales')">
                <v-expansion-panel-text>
                  <v-text-field v-model="cursoData.titulo" :label="t('Título')" :disabled="dialogType === 'delete'"
                    required></v-text-field>
                  <v-text-field v-model="cursoData.directorNombre" :label="t('Director')" readonly
                    append-icon="mdi-information" @click:append="() => openHelpDialog('director')"></v-text-field>
                  <v-textarea v-model="cursoData.descripcion" :label="t('Descripción')"
                    :disabled="dialogType === 'delete'" required append-icon="mdi-information"
                    @click:append="() => openHelpDialog('descripcion')"></v-textarea>
                  <v-textarea v-model="cursoData.presentacion" append-icon="mdi-information"
                    @click:append="() => openHelpDialog('presentacion')" :label="t('Presentación')"
                    :disabled="dialogType === 'delete'" required></v-textarea>
                  <v-file-input v-model="cursoData.imagen" :label="t('Imagen')" prepend-inner-icon="mdi-camera"
                    accept="image/*" @change="handleFileUpload" append-icon="mdi-information"
                    @click:append="() => openHelpDialog('imagen')"></v-file-input>
                </v-expansion-panel-text>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-title>
                  <template v-slot:default>
                    <v-row no-gutters>
                      <v-col class="d-flex justify-start" cols="4">
                        {{ t('Acceso') }}
                      </v-col>
                      <v-col class="text-grey" cols="8">
                        {{ cursoData.estado_curso }}
                      </v-col>
                    </v-row>
                  </template>

                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  <v-autocomplete multiple v-model="cursoData.profesionales" :items="tiposProfesionales"
                    item-title="categoria" item-value="id" :label="t('Profesional')" append-icon="mdi-information"
                    @click:append="() => openHelpDialog('profesional')"></v-autocomplete>
                  <v-textarea v-model="emailsRaw"
                    label="Pega los emails de las personas a las que quieras conceder acceso (@vallhebron.cat)"
                    hint="Puedes copiar y pegar los emails seleccionandolos todos a la vez en una columna de Excel"
                    persistent-hint auto-grow :rows="3"></v-textarea>
                </v-expansion-panel-text>
              </v-expansion-panel>
              <v-expansion-panel :title="t('Características_avanzadas_opcionales')">
                <v-expansion-panel-text>
                  <v-select multiple v-model="cursoData.rama" :items="tiposRamas" item-title="categoria" item-value="id"
                    :label="t('Áreas')" required append-icon="mdi-information"
                    @click:append="() => openHelpDialog('areas')"></v-select>
                  <v-text-field v-model="cursoData.fecha_inicio" :label="t('Fecha_de_inicio')"
                    type="date"></v-text-field>
                  <v-text-field v-model="cursoData.fecha_fin" :label="t('Fecha_de_fin')" type="date"></v-text-field>
                  <v-text-field v-model="cursoData.experiencia_minima" :label="t('Experiencia mínima')"
                    type="number"></v-text-field>
                  <v-text-field v-model="cursoData.experiencia_maxima" :label="t('Experiencia máxima')"
                    type="number"></v-text-field>
                  <v-text-field v-model="cursoData.experiencia_docente" :label="t('Experiencia docente')"
                    type="number"></v-text-field>
                  <v-text-field v-model="cursoData.afos" label="AFOS" append-icon="mdi-information"
                    @click:append="() => openHelpDialog('afos')"></v-text-field>
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="handleSubmit" prepend-icon="mdi-content-save">{{ t('Guardar') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="helpDialog" max-width="600px">
      <v-card>
        <v-card-title class="text-h5">{{ t('Ayuda') }}</v-card-title>
        <v-card-text>{{ helpText }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="helpDialog = false">{{ t('Cerrar') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { axios, setAuthToken } from '../axios';
import LlistaActivitats from './LlistaActivitats.vue';
import { useI18n } from 'vue-i18n';
export default {
  setup() {
    const { t } = useI18n();
    return {
      t
    };
  },
  components: {
    LlistaActivitats
  },
  data() {
    return {
      cursos: [],
      cursosdirigidos: [],
      isLoadingCursos: true,
      isLoadingDirigidos: true,
      tiposProfesionales: [],
      tiposRamas: [],
      error: '',
      titol_teves: this.t('Tus_actividades_docentes'),
      titol: this.t('Inspira_tus_alumnos'),
      url: '/curso/',
      boto: 'crea_activitat',
      dialog: false,
      userDetails: [],
      panel: [0, 1],
      snackbarText: '',
      emailsRaw: '',
      helpDialog: false,
      helpText: '',
      cursoData: {
        titulo: null,
        descripcion: null,
        directorNombre: null,
        fecha_inicio: null,
        fecha_fin: null,
        experiencia_minima: 0,
        experiencia_maxima: 0,
        experiencia_docente: 0,
        profesionales: [],
        rama: [1],
        imagen: null,
        estado_curso: 'privado',
        admitidos: [],
        afos: null,
        porcentaje_teorico: 50,
        puntaje_practico_diez: 10
      }
    }
  },
  watch: {
    emailsRaw(newVal) {
      this.processPastedEmails(newVal);
    }
  },
  methods: {
    openHelpDialog(field) {
      this.helpDialog = true;
      switch (field) {
        case 'descripcion':
          this.helpText = 'Describe tu actividad con una frase atractiva.';
          break;
        case 'presentacion':
          this.helpText = 'Haz una presentación o introducción a tu actividad.';
          break;
        case 'imagen':
          this.helpText = 'Sube una imagen representativa de la actividad.';
          break;
        case 'profesional':
          this.helpText = '¿A qué profesionales va dirigida tu actividad? Marca todos los que apliquen.';
          break;
        case 'director':
          this.helpText = 'El director es la persona responsable del contenido de la actividad. El director puede editar el contenido de su actividad y su acceso por parte de otros usuarios.';
          break;
        case 'docentes':
          this.helpText = 'Puedes dar acceso docente a otros profesionales registrados que estén relacionados con la especialidad o categoría profesional de tu actividad. Los docentes tienen acceso a la guía docente de la actividad y pueden evaluar los alumnos.';
          break;
        case 'areas':
          this.helpText = '¿En qué URLs tiene que estar disponible tu actividad? Por defecto selecciona vallhebron (vallhebron.simulacio.com). Si deseas que tu actividad esté disponible en alguna de las otras URLs (por ejemplo gine.simulacio.com), seleccionalas.';
          break;
        case 'afos':
          this.helpText = 'El código AFOS sirve para identificar tu actividad en el Centre de Simulació. Para que el control de asistencia con QR funcione correctamente es fundamental que el código coincida exactamente con el código introducido en el sistema RedCap del centro.';
          break;
        default:
          this.helpText = 'Información no disponible.';
      }
    },
    processPastedEmails(emailsString) {
      const emails = emailsString.split(/\r?\n/); // Separa por saltos de línea
      this.cursoData.admitidos = emails.map(email => email.trim()).filter(email => email);
    },
    async fetchCursos() {
      try {
        const response = await axios.get(`inspira/`, {
        });
        this.cursos = response.data;
        this.error = ''; // Reinicia el mensaje de error en caso de éxito        
      } catch (error) {
        console.error("Error al obtener los cursos:", error);
      }
      this.isLoadingCursos = false;
    },
    async fetchCursosDirigidos() {
      try {
        const response = await axios.get(`cursos-dirigidos/`, {
        });
        this.cursosdirigidos = response.data;
        this.error = ''; // Reinicia el mensaje de error en caso de éxito
      } catch (error) {
        console.error("Error al obtener los cursos:", error);
      }
      this.isLoadingDirigidos = false;
    },

    async fetchUser() {
      const userResponse = await axios.get(`userdetails/`, {
      });
      // Asegúrate de que las respuestas están en el formato correcto
      if (!userResponse.data) throw new Error("Formato de respuesta no válido");
      this.cursoData.directorNombre = userResponse.data.first_name + ' ' + userResponse.data.last_name;
      this.userDetails = {
        ...userResponse.data,
      };
    },
    async fetchProfesionales() {
      try {
        const response = await axios.get(`profesionales/`, {
        });
        this.tiposProfesionales = response.data.sort((a, b) => a.categoria.localeCompare(b.categoria));
      } catch (error) {
        console.error(error);
      }
    },
    async fetchRamas() {
      try {
        const response = await axios.get(`ramas/`, {
        });
        this.tiposRamas = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    openDialog() {
      if (this.userDetails.vallhebron) {
        this.dialog = true;  // Cambia el estado para abrir el diálogo
      } else {
        this.snackbarText = this.t("Sólo_pueden_crear_actividades_los_profesionales_de_Vallhebron");
        this.$store.dispatch('triggerSnackbar', { message: this.snackbarText, color: 'info' });
      }

    },
    tancarDialog() {
      this.dialog = false;  // Cambia el estado para cerrar el diálogo
    },
    closeDialog() {
      this.$emit('update:dialog', false);
    },
    handleFileUpload(event) {
      if (event.target.files && event.target.files.length > 0) {
        this.cursoData.imagen = event.target.files[0]; // Asegura que estamos tomando el primer archivo
      } else {
        this.cursoData.imagen = null; // Asegura limpiar el campo si no hay archivo
      }
    },
    async handleSubmit() {
      // Verificar si se ha seleccionado al menos una 'rama'
      if (this.cursoData.rama.length === 0) {
        this.snackbarText = this.t("Debes_seleccionar_al_menos_una_área_en_la_sección_de_visibilidad");
        this.snackbarColor = 'error';
        this.snackbar = true;
        return; // Detener la ejecución si no se cumple la condición
      }
      const formData = new FormData();
      Object.keys(this.cursoData).forEach(key => {
        const value = this.cursoData[key];
        // Comprueba si el valor no es null antes de agregarlo al FormData
        if (value !== null && value !== undefined) {
          if (key === 'imagen' && value instanceof File) {
            formData.append(key, value);
          } else if (key === 'admitidos' && typeof value === 'object') {
            formData.append(key, JSON.stringify(value)); // Convertir el objeto a JSON
          } else if (key === 'profesionales' || key === 'rama') {
            value.forEach(item => {
              formData.append(key, item);
            });
          } else {
            formData.append(key, value);
          }
        }
      });

      // Realiza la llamada API POST con axios
      await axios.post('cursos/', formData).then(response => {
        // Manejo de la respuesta o la actualización de la interfaz
        this.snackbarText = response.data.titulo + ' ' + this.t("fue_creado_con_éxito");
        this.$store.dispatch('triggerSnackbar', { message: this.snackbarText, color: 'success' });
        this.dialog = false;
        this.fetchCursos(); // Refrescar lista de cursos
        this.fetchCursosDirigidos();
      }).catch(error => {
        if (error.response) {
          // Si hay errores que no están asociados a un campo específico
          if (error.response.data.non_field_errors) {
            this.snackbarText = "Error: " + error.response.data.non_field_errors.join(', ');
          }
          // Si quieres manejar errores específicos de campos, puedes agregar aquí
          else if (error.response.data.detail) {
            // Si el error es un mensaje general (por ejemplo, un error de autenticación)
            this.snackbarText = "Error: " + error.response.data.detail;
          }
          // Manejar errores específicos de campos
          else {
            this.snackbarText = '';
            // Iterar sobre las claves de los errores para formar un mensaje detallado
            Object.keys(error.response.data).forEach(key => {
              this.snackbarText += `${key}: ${error.response.data[key].join(', ')}; `;
            });
          }
        }
        this.$store.dispatch('triggerSnackbar', { message: this.snackbarText, color: 'error' });
      });
    }
  },
  mounted() {
    const token = localStorage.getItem('authToken');
    setAuthToken(token);
  },
  created() {
    this.fetchUser();
    this.fetchProfesionales();
    this.fetchRamas();
    this.fetchCursos()
    this.fetchCursosDirigidos();
  }
}
</script>
