<template>
  <v-container>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card class="pa-4 ma-3" elevation="2">
          <v-card-title>{{ t('Tus_reservas') }}</v-card-title>
          <ReservationList ref="resList" @reservation-cancelled="handleCancellation" />
        </v-card>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card class="pa-4 ma-3" elevation="2">
          <v-row>
            <v-col cols="12" md="6">
              <v-toolbar>
                <v-toolbar-title>1. {{ t('Selecciona_un_simulador') }}</v-toolbar-title>
              </v-toolbar>
              <v-list>
                <v-list-item v-for="combinacion in combinacionesCursoSimulador" :key="combinacion.clave"
                  @click="selectCombinacion(combinacion.cursoId, combinacion.simuladorId)">
                  <v-btn block @click="selectCombinacion(combinacion.cursoId, combinacion.simuladorId)"
                    :color="esCombinacionSeleccionada(combinacion.cursoId, combinacion.simuladorId) ? 'primary' : ''">
                    {{ combinacion.nombreCurso }} ({{ combinacion.nombreSimulador }})
                  </v-btn></v-list-item>
              </v-list>
            </v-col>
            <!-- Selector de Mes y Calendario -->
            <v-col cols="12" md="6">
              <v-toolbar>
                <v-toolbar-title>2. {{ t('Elige_un_día') }}</v-toolbar-title>
              </v-toolbar>
              <v-row justify="center" align="center">
                <v-sheet :height="5" :width="200"></v-sheet>
                <template v-if="selectedSimulator">
                  <v-col cols="12">
                    <v-sheet class="d-flex align-center justify-center flex-wrap text-center">
                      <template v-if="isLoading">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                        {{ t('cargando_reservas') }}
                      </template>
                      <template v-if="!isLoading">
                        {{ t('Selecciona_un_día_para_ver_disponibilidad') }}
                      </template>
                    </v-sheet>
                  </v-col>
                  <v-row justify="center" align="center">
                    <v-col cols="auto">
                      <v-btn @click="previousMonth">
                        <v-icon left>mdi-chevron-left</v-icon>
                        <span class="hidden-xs">{{ t('anterior') }}</span>
                      </v-btn>
                    </v-col>

                    <v-col cols="auto">
                      <v-chip class="mx-2" color="primary" text-color="white">
                        {{ currentMonth }} {{ currentYear }}
                      </v-chip>
                    </v-col>

                    <v-col cols="auto">
                      <v-btn @click="nextMonth">
                        <span class="hidden-xs">{{ t('siguiente') }}</span>
                        <v-icon right>mdi-chevron-right</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>

                  <v-col cols="12">
                    <div class="days">
                      <v-chip v-for="day in daysOfWeek" :key="day" outlined>{{ day }}</v-chip>
                    </div>
                    <div class="dates">
                      <v-chip v-for="(date, index) in datesOfMonth" :key="date ? date.getTime() : 'empty-' + index"
                        :color="dateChipColor(date)" @click="date ? selectDate(date) : null">
                        {{ date ? date.getDate() : '' }}
                      </v-chip>
                    </div>
                  </v-col>
                </template>
              </v-row>
            </v-col>

            <v-row v-if="selectedDayTimeSlots.length > 0">
              <v-col cols="12">
                <v-list lines="two">
                  <v-list-subheader>{{ selectedSimulatorData.nombre }} - {{
            selectedDate.toLocaleDateString() }}</v-list-subheader>
                  <v-list-item v-for="slot in selectedDayTimeSlots" :key="slot.id">
                    <v-list-item-title>
                      {{ formatTime(slot.inicio) }} a {{ formatTime(slot.fin) }}
                      <span v-if="slot.alumnos && slot.alumnos.length >= selectedSimulatorData.num_alumnos">({{
            t('reservado')
          }})</span>
                      <span v-else>({{ slotAvailability(slot) }})</span>
                    </v-list-item-title>

                    <v-list-item-subtitle>
                      {{ selectedSimulatorData.nombre }} <span v-if="slot.curso">({{ slot.curso.titulo }})</span> - {{
            selectedDate.toLocaleDateString() }}
                    </v-list-item-subtitle>
                    <template v-slot:append>
                      <span v-if="slot.alumnos && slot.alumnos.length >= selectedSimulatorData.num_alumnos">
                        <v-btn small disabled class="ma-2">
                          <v-icon>mdi-calendar-plus</v-icon>
                          <span class="d-none d-sm-inline">{{ t('reservado') }}</span>
                        </v-btn>
                      </span>
                      <span v-else>
                        <v-btn small color="green" class="ma-2" @click="reservarSlot(slot.id)"
                          v-if="!isSlotInPast(slot)">
                          <v-icon>mdi-calendar-plus</v-icon>
                          <span class="d-none d-sm-inline">{{ t('Reservar') }}</span>
                        </v-btn>
                      </span>
                    </template>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
            <v-alert v-else color="info" class="ma-3">
              {{ t('No_hay_disponibilidad_de_reservas_para_el_simulador_de') }} {{ selectedSimulatorData.nombre }} {{
            t('el_día') }} {{
            selectedDate.getDate() }} de {{ monthName(selectedDate) }}.
            </v-alert>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { axios } from '../axios';
import ReservationList from './ReservationList.vue'

import { useI18n } from 'vue-i18n';
export default {
  setup() {
    const { t } = useI18n();
    return {
      t
    };
  },
  components: {
    ReservationList
  },
  data() {
    return {
      cursos: [],
      simuladores: [],
      timeSlots: [],
      selectedCurso: null,
      selectedSimulator: null,
      selectedDayTimeSlots: [],
      selectedDate: new Date(),
      currentDate: new Date(),
      daysOfWeek: ['Dl', 'Dt', 'Dc', 'Dj', 'Dv', 'Ds', 'Dm'],
      isLoading: false,
    };
  },
  computed: {
    combinacionesCursoSimulador() {
      let combinaciones = [];
      this.cursos.forEach(curso => {
        curso.simuladores.forEach(simuladorId => {
          const simulador = this.simuladores.find(sim => sim.id === simuladorId);
          if (simulador) {
            combinaciones.push({
              clave: `${curso.id}-${simulador.id}`,
              cursoId: curso.id,
              nombreCurso: curso.titulo,
              simuladorId: simulador.id,
              nombreSimulador: simulador.nombre,
            });
          }
        });
      });
      return combinaciones;
    },
    selectedSimulatorData() {
      return this.simuladores.find(sim => sim.id === this.selectedSimulator);
    },
    currentYear() {
      return this.currentDate.getFullYear();
    },
    currentMonth() {
      return this.currentDate.toLocaleString('default', { month: 'long' });
    },
    firstDayOfMonth() {
      let firstDay = new Date(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth(),
        1
      ).getDay();
      return firstDay === 0 ? 6 : firstDay - 1;
    },
    datesOfMonth() {
      const start = new Date(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth(),
        1
      );
      const end = new Date(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth() + 1,
        0
      );

      const dates = [];
      const firstDay = this.firstDayOfMonth; // Usamos firstDayOfMonth aquí

      // Agregar fechas "falsas" al inicio reservado
      for (let i = 0; i < firstDay; i++) {
        dates.push(null);
      }

      for (let i = start.getDate(); i <= end.getDate(); i++) {
        dates.push(new Date(start.getFullYear(), start.getMonth(), i));
      }

      return dates;
    },
  },
  methods: {
    selectCombinacion(cursoId, simuladorId) {
      this.selectedCurso = cursoId;
      this.selectedSimulator = simuladorId;
    },

    esCombinacionSeleccionada(cursoId, simuladorId) {
      return this.selectedCurso === cursoId && this.selectedSimulator === simuladorId;
    },

    isSlotInPast(slot) {
      const now = new Date();
      const slotDate = new Date(slot.fin);
      return slotDate < now;
    },

    fetchSimuladores() {
      axios.get(`simuladorlist/`).then(response => {
        this.tsimuladores = response.data;
      });
    },

    async fetchCursos() {
      try {
        await this.fetchSimuladores();
        const response = await axios.get(`apren/`, {
        });

        this.cursos = response.data;
        const simuladoresIds = new Set();
        this.cursos.forEach(curso => {
          curso.simuladores.forEach(id => {
            simuladoresIds.add(id);
          });
        });

        // Actualizar la lista de simuladores para incluir sólo aquellos a los que el usuario tiene acceso
        this.simuladores = this.tsimuladores.filter(simulador => simuladoresIds.has(simulador.id));
      } catch (error) {
        console.error("Error al obtener los cursos:", error);
      }
    },

    fetchTimeSlots() {
      this.isLoading = true; // Activar el estado de carga
      axios.get(`time-slots/?simulador=${this.selectedSimulator}`, {
      }).then(response => {
        this.timeSlots = response.data;
      }).catch(error => {
        console.error("Error al cargar los time slots:", error);
      }).finally(() => {
        this.isLoading = false; // Desactivar el estado de carga
      });
    },

    previousMonth() {
      this.currentDate.setMonth(this.currentDate.getMonth() - 1);
      this.currentDate = new Date(this.currentDate);
    },
    nextMonth() {
      this.currentDate.setMonth(this.currentDate.getMonth() + 1);
      this.currentDate = new Date(this.currentDate);
    },
    isToday(date) {
      const today = new Date();
      return (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
      );
    },
    isSelected(date) {
      return (
        date.getDate() === this.selectedDate.getDate() &&
        date.getMonth() === this.selectedDate.getMonth() &&
        date.getFullYear() === this.selectedDate.getFullYear()
      );
    },
    selectDate(date) {
      this.selectedDate = date;
      // Filtrar y guardar los time-slots del día seleccionado
      this.selectedDayTimeSlots = this.timeSlots.filter(slot => {
        const slotDate = new Date(slot.inicio);
        return slotDate.getFullYear() === date.getFullYear() &&
          slotDate.getMonth() === date.getMonth() &&
          slotDate.getDate() === date.getDate();
      });
    },
    availabilityOfDay(date) {
      // Filtrar los time-slots de la fecha pasada.
      const timeSlotsOfDay = this.timeSlots.filter(slot => {
        const slotDate = new Date(slot.inicio);
        return slotDate.getFullYear() === date.getFullYear() &&
          slotDate.getMonth() === date.getMonth() &&
          slotDate.getDate() === date.getDate();
      });

      // Si no hay time-slots para ese día, retornamos undefined.
      if (timeSlotsOfDay.length === 0) return;

      // Calcular la disponibilidad para cada time-slot y devolver un array de estados.
      return timeSlotsOfDay.map(slot => {
        const numAlumnos = this.selectedSimulatorData ? this.selectedSimulatorData.num_alumnos : 0;
        const alumnosReserved = slot.alumnos ? slot.alumnos.length : 0;
        if (alumnosReserved === 0) {
          return 'libre';
        } else if (alumnosReserved < numAlumnos) {
          return `ocupación ${alumnosReserved}/${numAlumnos}`;
        } else {
          return 'reservado';
        }
      });
    },
    monthName(date) {
      return date.toLocaleString(navigator.language, { month: 'long' });
    },
    async reservarSlot(slotId) {
      try {
        const response = await axios.post(`reservar_time_slot/${slotId}/`, {
          curso: this.selectedCurso,
        }, {
        });
        alert(response.data.message);
        this.$refs.resList.fetchUserReservations();
        this.fetchTimeSlots();
        this.selectDate(this.selectedDate);
      } catch (error) {
        alert("Error al reservar el time-slot. Recuerda que no es posible reservar más de un hueco en cada simulador.");
      }
    },
    async handleCancellation() {
      this.fetchTimeSlots();
      this.selectDate(this.selectedDate);
    },

    formatTime(timeString) {
      const tempsCadena = new Date(timeString);
      return `${tempsCadena.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
    },
    dateChipColor(date) {
      if (!date) {
        return 'transparent'; // para fechas falsas
      }
      if (this.isSelected(date)) {
        return 'blue'; // Selected date color
      }

      // Get the availability statuses for the given date.
      const availabilityStatuses = this.availabilityOfDay(date) || [];

      // If there are no time slots, return the default color.
      if (availabilityStatuses.length === 0) {
        return 'gray'; // Default color when there are no time slots
      }

      // Check the statuses to determine the color.
      let allAvailable = true;
      let anyReserved = false;

      for (const status of availabilityStatuses) {
        if (status.includes(this.$root.$t('ocupación'))) {
          allAvailable = false;
        }
        if (status === this.$root.$t('reservado')) {
          allAvailable = false;
          anyReserved = true;
          break; // No need to check further if a slot is fully reserved
        }
      }

      if (allAvailable) {
        return 'green'; // All slots available
      } else if (anyReserved) {
        return 'red'; // At least one slot fully reserved
      } else if (!allAvailable && !anyReserved) {
        return 'yellow'; // Some slots available
      }
      // If the logic somehow falls through, return 'gray' as a safe default
      return 'gray';
    },
    slotAvailability(slot) {
      const numAlumnos = this.selectedSimulatorData ? this.selectedSimulatorData.num_alumnos : 0;
      const alumnosReserved = slot.alumnos ? slot.alumnos.length : 0;
      const ocupacion = this.$root.$t('ocupación');

      if (alumnosReserved === 0) {
        return this.$root.$t('libre');
      } else if (alumnosReserved < numAlumnos) {
        return `${ocupacion} ${alumnosReserved}/${numAlumnos}`;
      } else {
        return this.$root.$t('reservado');
      }
    },
    getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
      return null;
    }

  },
  watch: {
    selectedSimulator: 'fetchTimeSlots',
    timeSlots() {
      this.selectDate(this.selectedDate);
    }
  },
  created() {
    this.fetchCursos();
  }
};
</script>

<style scoped>
.days,
.dates {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  /* Esto garantiza un ancho uniforme para cada celda */
  width: 400px;
  margin: auto;
}
</style>