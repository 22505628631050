<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-card class="pa-2 ma-3" elevation="2">
                    <v-card-title>{{ t('Detalles_del_usuario') }}</v-card-title>
                    <v-card-text>
                        <form @submit.prevent="updateUserDetails">
                            <v-text-field v-model="userDetails.nickname" :label="t('Seudónimo')"
                                ></v-text-field>
                            <v-text-field v-model="userDetails.email" disabled :label="t('Email')"
                                required></v-text-field>
                            <v-text-field v-model="userDetails.first_name" disabled :label="t('Nombre')"
                                required></v-text-field>
                            <v-text-field v-model="userDetails.last_name" disabled :label="t('Apellidos')"
                                required></v-text-field>
                            <v-text-field v-model="userDetails.fecha_inicio_especialidad" disabled
                                :label="t('Fecha_inicio_especialidad')" type="date" required></v-text-field>
                            <v-select v-model="userDetails.tipo_profesional" :items="tiposProfesionales"
                                item-title="categoria" item-value="categoria" :label="t('Profesional')"
                                disabled></v-select>
                            <v-text-field v-model="userDetails.dni" :label="t('DNI_opcional')" required></v-text-field>
                            <v-btn @click="updateUserDetails" color="primary">{{ t('Guardar_cambios') }}</v-btn>
                        </form>
                    </v-card-text>
                    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="10000">
                        {{ snackbarText }}
                    </v-snackbar>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { axios, setAuthToken } from '../axios';
import { useI18n } from 'vue-i18n';
export default {
    setup() {
        const { t } = useI18n();
        return {
            t
        };
    },
    data() {
        return {
            triggerReevaluation: 0,
            old_password: '',
            new_password1: '',
            new_password2: '',
            snackbar: false,
            snackbarColor: '', // 'success' o 'error'
            snackbarText: '',
            userDetails: {
                nickname: '',
                email: '',
                first_name: '',
                last_name: '',
                fecha_inicio_especialidad: '',
                tipo_profesional: '',
                dni: '',
            },
            tiposProfesionales: [],
            fields: {
                first_name: { disabled: false },
                last_name: { disabled: false },
                fecha_inicio_especialidad: { disabled: false },
                tipo_profesional: { disabled: false },
                dni: { disabled: false },
            },
        };
    },
    methods: {
        async changePassword() {
            try {
                const response = await axios.post(`changepassword/`, {
                    old_password: this.old_password,
                    new_password1: this.new_password1,
                    new_password2: this.new_password2
                }, {
                });

                this.snackbarText = response.data.detail;
                this.snackbarColor = 'success';
                this.snackbar = true;

            } catch (error) {
                this.snackbarText = error.response && error.response.data ? error.response.data : "Ocurrió un error desconocido.";
                this.snackbarColor = 'error';
                this.snackbar = true;
            }
        },
        async fetchProfesionales() {
            try {
                const response = await axios.get(`profesionales/`, {
                });
                this.tiposProfesionales = response.data;
            } catch (error) {
                console.error("Error al obtener las categorías de profesionales:", error);
            }
        },
        async fetchUserDetails() {
            try {
                await this.fetchProfesionales();
                const userResponse = await axios.get(`userdetails/`, {
                });

                // Asegúrate de que las respuestas están en el formato correcto
                if (!userResponse.data) throw new Error("Formato de respuesta no válido");

                // Combina los datos del usuario y los detalles en un solo objeto
                this.userDetails = {
                    ...userResponse.data,
                };
                if (userResponse.data.profesional) {
                    const tipoEncontrado = this.tiposProfesionales.find(tipo => tipo.id === userResponse.data.profesional);
                    this.userDetails.tipo_profesional = tipoEncontrado ? tipoEncontrado.categoria : null;
                } else {
                    this.userDetails.tipo_profesional = null;
                }

            } catch (error) {
                console.error("Error al obtener los detalles del usuario:", error);
                this.userDetails.tipo_profesional = null; // Asegurar que tipo_profesional es null si hay un error
            }
        },
        async updateUserDetails() {
            try {
                // Preparando los datos para enviar. Asegúrate de enviar solo los datos necesarios.
                const data = {
                    nickname: this.userDetails.nickname,
                    dni: this.userDetails.dni,
                };
                const response = await axios.put(`userdetails/`, data, {
                });

                // Manejo de la respuesta del servidor
                if (response.status === 200) {
                    this.snackbarText = this.t('Tus_datos_se_han_actualizado_correctamente');
                    this.snackbarColor = 'success';
                } else {
                    throw new Error('Algo salió mal al actualizar los detalles');
                }
            } catch (error) {
                // Captura el mensaje de error específico de la API
                if (error.response) {
                    // Check if the error message is in the 'detail' attribute
                    if (error.response.data.detail) {
                        this.snackbarText = "Error: " + error.response.data.detail;
                    }
                    // Check if errors are field-specific
                    else {
                        let errorMessages = [];
                        for (let field in error.response.data) {
                            let errorsForField = error.response.data[field];
                            // Asume que 'field' es el nombre del campo y 'errorsForField' son los mensajes de error
                            // Para cada campo, agrega el nombre del campo y su mensaje de error a 'errorMessages'
                            let fieldName = field.charAt(0).toUpperCase() + field.slice(1); // Capitaliza el nombre del campo
                            errorMessages.push(`${fieldName}: ${errorsForField.join(', ')}`);
                        }
                        // Une todos los mensajes de error con un punto y espacio para separarlos claramente
                        this.snackbarText = "Errores: " + errorMessages.join('. ');
                    }
                } else {
                    this.snackbarText = error.message || 'Ocurrió un error desconocido.';
                }
                this.snackbarColor = 'error';
            } finally {
                this.snackbar = true;
            }
        },

    },
    async mounted() {

        const token = localStorage.getItem('authToken');
        setAuthToken(token);

        try {
            await this.fetchUserDetails(); // Ahora puedes llamar a fetchUserDetails
        } catch (error) {
            console.error("Error en el montaje del componente:", error);
        }
    }
}
</script>