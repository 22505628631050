<template>
    <v-card variant="text">
        <v-img max-height="300" :src="pregunta.imagen" />
        <v-card-title  class="text-wrap" style="word-break: break-word">
            {{ pregunta.texto }}
        </v-card-title>
        <v-card-text>
            <v-list v-for="opcion in pregunta.opciones" :key="opcion.id" select-strategy="classic"
                :disabled="haRespondido">
                <v-list-item :value="opcion.id" @click="toggleCheckbox(opcion.id)">
                    <template v-slot:prepend>
                        <v-list-item-action start>
                            <v-checkbox-btn v-model="respuestasSeleccionadas" :value="opcion.id" />
                        </v-list-item-action>
                    </template>
                    <v-list-item-title class="text-wrap" style="word-break: break-word">{{ opcion.texto }}
                        <v-chip v-if="haRespondido"
                            :color="opcion.es_correcta === respuestasSeleccionadas.includes(opcion.id) ? 'green' : 'red'"
                            small>
                            {{ opcion.es_correcta === respuestasSeleccionadas.includes(opcion.id) ? t('Correcto') :
            t('Incorrecto') }}
                        </v-chip>
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="haRespondido && opcion.motivo" class="text-wrap" style="word-break: break-word">
                        {{ opcion.motivo }}
                    </v-list-item-subtitle>
                </v-list-item>
            </v-list>
        </v-card-text>
    </v-card>
    <v-btn block v-if="!haRespondido" :disabled="loading" color="primary" class="mt-3" @click="enviarCuestionario">
        {{ t('Comprueba') }}
    </v-btn>
    <v-btn block v-if="haRespondido" :disabled="loading" color="primary" class="mt-3" @click="next">
        {{ t('Continua') }}
    </v-btn>
</template>

<script setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { axios } from '../axios'; // Asumiendo que tienes configurado axios de esta manera

const props = defineProps({
    pregunta: {
        type: Object,
        default: () => ({})
    }
});

const emits = defineEmits(['respuesta-enviada']);

const { t } = useI18n();
const respuestasSeleccionadas = ref([]);
const haRespondido = ref(false);
const loading = ref(false);

function toggleCheckbox(opcionId) {
    const index = respuestasSeleccionadas.value.indexOf(opcionId);
    if (index > -1) {
        respuestasSeleccionadas.value.splice(index, 1);
    } else {
        respuestasSeleccionadas.value.push(opcionId);
    }
}

async function enviarCuestionario() {
    const datosParaEnviar = {
        opcion_respuesta_ids: respuestasSeleccionadas.value
    };
    loading.value = true;
    try {
        const respuesta = await axios.post(`enviar_respuestas/`, datosParaEnviar);
        if (respuesta.data.success) {
            haRespondido.value = true;
        } else {
            console.error('Error al enviar respuestas:', respuesta.data.message);
        }
        loading.value = false;
    } catch (error) {
        console.error('Error al realizar la solicitud:', error);
        loading.value = false;
    }
}

function next() {
    emits('respuesta-enviada', props.pregunta);
    respuestasSeleccionadas.value = [];
}
</script>