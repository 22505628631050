<template>
  <v-container fill-height>
    <v-row justify="center" align="center">
      <v-col cols="12" sm="12" md="8">
        <v-card class="ma-4 pa-2">
          <HelperCarregant v-if="loading" :missatge="t('Iniciando_la_sesión')" />
          <template v-if="!loading">
            <v-card-title>{{ t('Iniciar_sesión') }}</v-card-title>
            <v-card-text>
              <form @submit.prevent="login">
                <v-text-field v-model="email" prepend-icon="mdi-email" :label="t('Email')" type="email"
                  required></v-text-field>
                <v-text-field v-model="password" prepend-icon="mdi-lock" :label="t('Contraseña')" type="password"
                  required></v-text-field>
                <v-btn :loading="loading" block color="primary" type="submit">{{ t('Iniciar_sesión') }}</v-btn>
              </form>
              <v-divider class="my-4"></v-divider>
              <v-btn size="x-small" @click="$router.push('/passreset')">{{ t('No_recuerdas_tu_c') }}</v-btn>
            </v-card-text>
            <v-card-actions class="justify-center">
              <span>{{ t('No_tienes_cuenta') }}</span>
              <v-btn color="primary" @click="$router.push('/register')">{{ t('Regístrate') }}</v-btn>
            </v-card-actions>
          </template>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>

import { useI18n } from 'vue-i18n';
const { t } = useI18n();

</script>

<script>
import { axios, setAuthToken } from '../axios';
import HelperCarregant from './HelperCarregant.vue';

export default {
  components: {
    HelperCarregant,
  },

  data() {
    return {
      email: '',
      password: '',
      loading: false,
      snackbarText: '',
    };
  },
  methods: {
    async login() {
      this.loading = true;
      try {
        const response = await axios.post(`login/`, {
          email: this.email,
          password: this.password
        });
        localStorage.setItem('authToken', response.data.key);
        const token = localStorage.getItem('authToken');
        setAuthToken(token);
        this.$router.push('/');
      } catch (error) {
        if (error.response) {
          // Si hay errores que no están asociados a un campo específico
          if (error.response.data.non_field_errors) {
            this.snackbarText = "Error: " + error.response.data.non_field_errors.join(', ');
          }
          // Si quieres manejar errores específicos de campos, puedes agregar aquí
          else if (error.response.data.detail) {
            // Si el error es un mensaje general (por ejemplo, un error de autenticación)
            this.snackbarText = "Error: " + error.response.data.detail;
          }
          else {
            // Si hay otros tipos de estructuras de error
            this.snackbarText = error.response;
          }
        } else {
          // Si el error no viene del servidor
          this.snackbarText = error;
        }
        this.$store.dispatch('triggerSnackbar', { message: this.snackbarText, color: 'error' });
      } finally {
        this.loading = false;
      }
    },
  },
}
</script>