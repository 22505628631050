<template>
  <v-app-bar app>
    <template v-slot:prepend>
      <router-link to="/">
        <img :src="require('@/assets/logo_gine.png')" alt="GineSIM_logo" style="height: 40px;">
      </router-link>
    </template>
    <v-app-bar-title class="headline text-uppercase">
      <span>GINE</span>
      <span class="font-weight-light">PLANTILLES</span>
    </v-app-bar-title>

    <v-spacer></v-spacer>

    <!-- Botons desktop -->


    <v-menu>
      <template v-slot:activator="{ props }">
        <v-btn flat v-bind="props" rounded="0" class="h-100 hidden-xs">
          <v-icon>mdi-earth</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="setLocale('ca')">
          <v-list-item-title>Català</v-list-item-title>
        </v-list-item>
        <v-list-item @click="setLocale('en')">
          <v-list-item-title>English</v-list-item-title>
        </v-list-item>
        <v-list-item @click="setLocale('es')">
          <v-list-item-title>Español</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-menu v-if="isLoggedIn">
      <template v-slot:activator="{ props }">
        <v-btn flat v-bind="props" rounded="0" class="h-100">
          <v-icon>mdi-account</v-icon>
          {{ username }}
        </v-btn>
      </template>

      <v-list>
        <v-list-item :to="`/perfil`" @click="resetButtons">
          <v-list-item-title>{{ t('Perfil') }}</v-list-item-title>
        </v-list-item>
        <v-list-item :to="`/reservas`" @click="resetButtons">
          <v-list-item-title>{{ t('Tus_reservas') }}</v-list-item-title>
        </v-list-item>
        <v-list-item class="hidden-sm-and-up">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn flat v-bind="props" rounded="0" class="h-100">
                <v-icon>mdi-earth</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="setLocale('ca')">
                <v-list-item-title>Català</v-list-item-title>
              </v-list-item>
              <v-list-item @click="setLocale('en')">
                <v-list-item-title>English</v-list-item-title>
              </v-list-item>
              <v-list-item @click="setLocale('es')">
                <v-list-item-title>Español</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item>


        <v-list-item @click="logout">
          <v-list-item-title>{{ t('Cerrar_sesión') }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <!-- Botó Login -->
    <v-btn v-if="!isLoggedIn" text small color="primary" @click="$router.push('/login')">{{ t('Iniciar_sesión') }}</v-btn>
  </v-app-bar>
</template>

<script>
import { axios, setAuthToken } from '../axios';
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
export default {
  setup() {
    const selectedButton = ref(null);
    const router = useRouter();

    const { t, locale } = useI18n();
    // Recuperar el idioma guardado al cargar la aplicación
    const userLocale = localStorage.getItem('userLocale');
    if (userLocale) {
      locale.value = userLocale;
    }
    const selectButton = (button, path) => {
      selectedButton.value = button;
      router.push(path);
    };

    const resetButtons = () => {
      selectedButton.value = null;
    };
    return {
      t,
      locale,
      selectedButton,
      selectButton,
      resetButtons,
    };
  },
  data() {
    return {
      drawer: false,
      username: '',
      isLoggedIn: false,
    };
  },

  watch: {
    $route() {
      this.isLoggedIn = !!localStorage.getItem('authToken');
    }
  },
  created() {
    // Verifica si el usuario está logueado cuando el componente es creado
    this.isLoggedIn = !!localStorage.getItem('authToken');
  },
  methods: {
    logout() {
      axios.post(`logout/`)
        .then(response => {
          console.log('Respuesta del servidor:', response.data);
          // Aquí manejas la respuesta exitosa
          localStorage.removeItem('authToken');
          this.$router.push('/login');
        })
        .catch(error => {
          // Maneja errores aquí si es necesario
          console.error('Hubo un error al cerrar sesión: ', error);
        });

    },
    async fetchUser() {
      const userResponse = await axios.get(`userdetails/`, {
      });
      // Asegúrate de que las respuestas están en el formato correcto
      if (!userResponse.data) throw new Error("Formato de respuesta no válido");
      this.username = userResponse.data.nickname;
    },
    setLocale(newLocale) {
      this.locale = newLocale;
      localStorage.setItem('userLocale', newLocale);
    },

  },
  mounted() {
    const token = localStorage.getItem('authToken');
    setAuthToken(token);
    this.fetchUser();
  },
}
</script>