// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

const gineTheme = {
  dark: false,
  colors: {
    background: '#e9f2ed',
    surface: '#FFFFFF',
    primary: '#367d9c',
    'primary-darken-1': '#00335d',
    secondary: '#e48077',
    'secondary-darken-1': '#D24520',
    error: '#B00020',
    info: '#e3e4e6',
    success: '#4CAF50',
    warning: '#FB8C00',
  },
  backgroundImage: '@/assets/fons/fons_gine.webp',
  branca: 'GineSIM',
  branca_code: 'gine',
}

const vallhebronTheme = {
  dark: false,
  colors: {
    background: '#F8F9FA',
    surface: '#FFFFFF',
    primary: '#004eb3',
    'primary-darken-1': '#00335d',
    secondary: '#333333',
    'secondary-darken-1': '#D24520',
    error: '#B00020',
    info: '#e3e4e6',
    success: '#4CAF50',
    warning: '#FB8C00',
  },
  backgroundImage: '@/assets/fons/fons_vallhebron.webp',
  branca: "Vall d'Hebron Centre de Simulació Clínica Avançada",
  branca_code: 'vallhebron',
}

const cardioTheme = {
  dark: false,
  colors: {
    background: '#fff4e6',
    surface: '#FFFFFF',
    primary: '#660713',
    'primary-darken-1': '#00335d',
    secondary: '#4a6a6b',
    'secondary-darken-1': '#D24520',
    error: '#B00020',
    info: '#e3e4e6',
    success: '#4CAF50',
    warning: '#FB8C00',
  },
  backgroundImage: '@/assets/fons/fons_cardio.webp',
  branca: 'CardioSIM',
  branca_code: 'cardio',
}

const baseTheme = {
  dark: false,
  colors: {
    background: '#F8F9FA',
    surface: '#FFFFFF',
    primary: '#004eb3',
    'primary-darken-1': '#00335d',
    secondary: '#333333',
    'secondary-darken-1': '#D24520',
    error: '#B00020',
    info: '#e3e4e6',
    success: '#4CAF50',
    warning: '#FB8C00',
  },
  backgroundImage: '@/assets/fons/fons_vallhebron.webp',
  branca: 'Simulacio.com',
  branca_code: '',
}

export default createVuetify({
  theme: {
    defaultTheme: 'vallhebronTheme',
    themes: {
      gineTheme,
      vallhebronTheme,
      cardioTheme,
      baseTheme,
    },
  },
})
