<template>
  <v-card variant="text">
    <v-img max-height="300" :src="pregunta.imagen" />
    <v-card-title class="text-wrap" style="word-break: break-word">
      {{ pregunta.texto }}
    </v-card-title>
    <v-card-text>
      <draggable v-model="questionOptions" tag="ul" group="questions" :animation="300" :disabled="haRespondido">
        <template #item="{ element: opcion }">
          <v-list>
            <v-list-item variant="tonal" :base-color="getColor(opcion)">{{ opcion.texto }}
              <template v-slot:append>
                <v-chip v-if="haRespondido">
                  {{ opcion.orden }}
                </v-chip>
              </template>
            </v-list-item>
          </v-list>
        </template>
      </draggable>
    </v-card-text>
  </v-card>
  <v-btn block v-if="!haRespondido" :disabled="loading" color="primary" class="mt-3" @click="enviarCuestionario">
    {{ t('Comprueba') }}
  </v-btn>
  <v-btn block v-if="haRespondido" :disabled="loading" color="primary" class="mt-3" @click="next">
    {{ t('Continua') }}
  </v-btn>
</template>

<script setup>
import { ref, watchEffect } from 'vue';
import draggable from 'vuedraggable';
import { useI18n } from 'vue-i18n';
import { axios } from '../axios';

const props = defineProps({
  pregunta: {
    type: Object,
    default: () => ({})
  }
});

const emits = defineEmits(['respuesta-enviada']);

const { t } = useI18n();
const respuestasSeleccionadas = ref([]);
const haRespondido = ref(false);
const loading = ref(false);

// Observar y reaccionar a cambios en pregunta.opciones
const questionOptions = ref([]);
watchEffect(() => {
  questionOptions.value = props.pregunta.opciones.map(opcion => ({
    ...opcion,
    correct: null // null means not checked yet
  }));
});

function getColor(opcion) {
  if (!haRespondido.value) return 'primary'; // Default color before response
  return opcion.correct ? 'green' : 'red';
}

async function enviarCuestionario() {
  loading.value = true;
  questionOptions.value.forEach((opcion, index) => {
    // Check if each option is in the correct place
    opcion.correct = opcion.orden - 1 === index;
  });
  const opcionesCorrectas = questionOptions.value.filter(opcion => opcion.correct);
  const datosParaEnviar = {
    opcion_respuesta_ids: opcionesCorrectas.map(opcion => opcion.id)
  };
  try {
    const respuesta = await axios.post(`enviar_respuestas/`, datosParaEnviar);
    if (respuesta.data.success) {
      haRespondido.value = true;
    } else {
      console.error('Error al enviar respuestas:', respuesta.data.message);
    }
    loading.value = false;
  } catch (error) {
    console.error('Error al realizar la solicitud:', error);
    loading.value = false;
  }
}

function next() {
  emits('respuesta-enviada', props.pregunta);
  respuestasSeleccionadas.value = [];
}
</script>