<template>
    <div class="markdown-body" v-html="compiledMarkdown"></div>
</template>


<script>
import { marked } from 'marked';

export default {
    props: {
        markdownText: {
            type: String,
            default: ''
        }
    },
    computed: {
        compiledMarkdown() {
            return marked(this.markdownText);
        }
    },
    methods: {
        handleDynamicLinkClick(e) {
            if (e.target.matches('.dynamic-link')) {
                e.preventDefault();
                const path = e.target.getAttribute('data-path');
                this.$router.push(path);
            }
        },
    },
    mounted() {
        document.addEventListener('click', this.handleDynamicLinkClick);
    },
    beforeUnmount() {
        document.removeEventListener('click', this.handleDynamicLinkClick);
    },
};
</script>

<style>
/* Estilos para listas */
.markdown-body ul,
.markdown-body ol {
    padding-left: 20px;
    /* Indentación para listas */
    margin-left: 1em;
    /* Espacio izquierdo adicional para alineación */
    margin-top: 10px;
    /* Espacio superior para listas */
    margin-bottom: 10px;
    /* Espacio inferior después de listas */
}

.markdown-body li {
    margin-bottom: 0.5em;
    /* Espacio entre ítems de lista */
}

/* Estilos para títulos */
.markdown-body h1,
.markdown-body h2,
.markdown-body h3,
.markdown-body h4,
.markdown-body h5,
.markdown-body h6 {
    margin-top: 24px;
    /* Espacio antes de cada título */
    margin-bottom: 16px;
    /* Espacio después de cada título */
    font-weight: bold;
    /* Fuente en negrita para destacar los títulos */
}

/* Estilos para párrafos */
.markdown-body p {
    margin-top: 0;
    /* Elimina el margen superior para pegar el texto al título si es necesario */
    margin-bottom: 10px;
    /* Espacio después de cada párrafo */
    text-align: justify;
}
</style>