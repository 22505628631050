<template>
  <v-container>
    <!-- Centra el saludo y la frase inspiradora -->
    <v-row justify="center">
      <v-progress-circular v-if="loading" color="primary" indeterminate></v-progress-circular>
      <v-col v-if="!loading" cols="12" class="text-center">
        <h1 class="mb-3">{{ t('Hola') }} {{ username }}</h1>
        <div v-if="inspirationalQuote" class="font-italic">{{ inspirationalQuote }}</div>
      </v-col>
    </v-row>

    <!-- Ajusta los componentes a la mitad del ancho cada uno -->
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-toolbar>
            <v-toolbar-title>{{ t('Tus_reservas') }}</v-toolbar-title>
            
            <v-btn icon :to="`/reservas`">
              <v-icon>mdi-calendar-month</v-icon>
            </v-btn>
          </v-toolbar>
          <ReservationList></ReservationList>
        </v-card>
      </v-col>
    </v-row>

    <!-- Coloca el texto de exploración de actividades abajo -->
    <v-row>
      <v-col cols="12" class="text-center">
        <h2 class="mt-6 mb-6">{{ t('Explora_las_actividades') }}</h2>
        <TotesActivitats></TotesActivitats>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { axios, setAuthToken } from '../axios';
import ReservationList from './ReservationList.vue'
import TotesActivitats from './TotesActivitats.vue'
import { useI18n } from 'vue-i18n';

export default {
  components: {
    ReservationList,
    TotesActivitats
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    };
  },
  data() {
    return {
      username: '',
      inspirationalQuote: '',
      loading: true,
    };
  },
  methods: {
    getInspirationalQuote() {
      const hostname = window.location.hostname;
      fetch(`https://${hostname}/api/get-quote`)
        .then(response => response.json())
        .then(data => {
          this.inspirationalQuote = data.quote;
        });
    },
    async fetchUser() {
      this.loading = true
      const userResponse = await axios.get(`userdetails/`, {
      });
      // Asegúrate de que las respuestas están en el formato correcto
      if (!userResponse.data) throw new Error("Formato de respuesta no válido");
      this.username = userResponse.data.nickname;
      this.loading = false
    },
  },
  mounted() {
    const token = localStorage.getItem('authToken');
    setAuthToken(token);
    this.fetchUser();
    this.getInspirationalQuote();
  }
}
</script>