<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title>{{ t('Restablecer_Contraseña') }}</v-card-title>
          <v-card-text>
            <form @submit.prevent="resetPassword">
              <v-text-field v-model="new_password1" :label="t('Nueva_contraseña')" type="password">
              </v-text-field>
              <v-text-field v-model="new_password2" :label="t('Confirmar_nueva_contraseña')" type="password">
              </v-text-field>
              <v-btn type="submit" color="primary">{{ t('Restablecer_Contraseña') }}</v-btn>
            </form>
          </v-card-text>
        </v-card>
        <v-snackbar :model-value="showSnackbar" :color="success ? 'green' : 'red'" :timeout="5000" :bottom="true"
          @update:model-value="showSnackbar = false">
          {{ message }}
        </v-snackbar>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import { axios, setAuthToken } from '../axios';
import { useI18n } from 'vue-i18n';
export default {
  setup() {
    const { t } = useI18n();
    return {
      t
    };
  },
  data() {
    return {
      new_password1: '',
      new_password2: '',
      message: '', // Variable para almacenar el mensaje de éxito o error
      success: false, // Variable para determinar si la operación fue exitosa o no
      showSnackbar: false,
    };
  },
  computed: {
    uid() {
      return this.$route.params.uid;
    },
    token() {
      return this.$route.params.token;
    },
  },
  methods: {
    async resetPassword() {
      try {
        await axios.post(`reset/${this.uid}/${this.token}/`, {
          new_password1: this.new_password1,
          new_password2: this.new_password2,
          uid: this.uid,
          token: this.token,
        });
        // Asignar un mensaje de éxito y cambiar el estado de success a true
        this.message = this.t('Contraseña_restablecida_con_éxito');
        this.success = true;
        this.showSnackbar = true;

        // Después de mostrar el mensaje de éxito, redirigir al usuario
        setTimeout(() => {
          this.showSnackbar = false;
          this.logout();
        }, 3000);

      } catch (error) {
        if (error.response && error.response.data) {
          // Intenta capturar errores específicos de los campos
          if (error.response.data.new_password1) {
            this.message = error.response.data.new_password1.join(' ');
          } else if (error.response.data.new_password2) {
            this.message = error.response.data.new_password2.join(' ');
          }
        } else {
          // Mensaje de error genérico
          this.message = 'Error al restablecer la contraseña, inténtelo de nuevo.';

        }
        this.success = false;
        this.showSnackbar = true;
        setTimeout(() => {
          this.showSnackbar = false;
        }, 5000);
      }
    },
    logout() {
      // Eliminar el token de autenticación del almacenamiento local
      localStorage.removeItem('authToken');

      // Redirigir al usuario a la página de inicio de sesión
      this.$router.push('/login');
    }
  },
  mounted() {
    const token = localStorage.getItem('authToken');
    setAuthToken(token);
  },
};
</script>
