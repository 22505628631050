<template>
  <v-card variant="text">
    <v-card-title>{{ t('Reservas') }}</v-card-title>
    <v-row justify="center" align="center">
      <v-col cols="auto">
        <v-btn @click="previousMonth">
          <v-icon left>mdi-chevron-left</v-icon>
          <span class="hidden-xs">{{ t('anterior') }}</span>
        </v-btn>
      </v-col>

      <v-col cols="auto">
        <v-chip class="mx-2" color="primary" text-color="white">
          {{ currentMonth }}
        </v-chip>
      </v-col>

      <v-col cols="auto">
        <v-btn @click="nextMonth">
          <span class="hidden-xs">{{ t('siguiente') }}</span>
          <v-icon right>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-text-field class="mx-4 mt-4" v-model="search" label="Cerca" prepend-inner-icon="mdi-magnify" single-line
      variant="outlined" hide-details></v-text-field>
    <v-data-table-virtual :headers="headers" :items="timeSlots" :search="search" :loading="loading">
      <template v-slot:loading>
        <v-skeleton-loader type="table-row@10"></v-skeleton-loader>
      </template>
    </v-data-table-virtual>
  </v-card>
</template>

<script>
import { ref, onMounted, watch, computed } from 'vue';
import { axios } from '../axios';
import { useI18n } from 'vue-i18n';
import { format, addMonths, subMonths } from 'date-fns'; // Importa la función format

export default {
  setup() {
    const { t } = useI18n();
    const selectedDate = ref(new Date());
    const timeSlots = ref([]); // Asume que timeSlots se carga desde algún lugar
    const loading = ref(false);

    // Funciones para manejar los cambios de mes
    const previousMonth = () => {
      selectedDate.value = subMonths(selectedDate.value, 1);
    };
    const nextMonth = () => {
      selectedDate.value = addMonths(selectedDate.value, 1);
    };
    const currentMonth = computed(() => {
      return selectedDate.value.toLocaleString('default', { month: 'long', year: 'numeric' });
    });

    // Observador que detecta cambios en selectedDate y actualiza los timeSlots
    watch(selectedDate, () => {
      if (selectedDate.value) {
        fetchTimeSlots();
      }
    });

    // Configura las cabeceras de la tabla
    const headers = ref([
      { title: t('Actividad'), key: 'curso_titulo' },
      { title: t('Fecha_de_inicio'), key: 'fechaInicio' },
      { title: t('Hora_de_inicio'), key: 'horaInicio' },
      { title: t('Fecha_de_fin'), key: 'fechaFin' },
      { title: t('Hora_de_fin'), key: 'horaFin' },
      { title: t('Alumnos'), key: 'emailsAlumnos' },
      { title: t('Docentes'), key: 'emailsDocentes' },
      { title: t('Simulador'), key: 'nombreSimulador' }
    ]);

    // Método para formatear las fechas y horas
    const formatDates = (timeSlots) => {
      return timeSlots.map(slot => ({
        ...slot,
        fechaInicio: format(new Date(slot.inicio), 'dd/MM/yyyy'),
        horaInicio: format(new Date(slot.inicio), 'HH:mm'),
        fechaFin: format(new Date(slot.fin), 'dd/MM/yyyy'),
        horaFin: format(new Date(slot.fin), 'HH:mm'),
        emailsAlumnos: slot.alumnos.map(alumno => alumno.email).join(', '), // Concatena los emails
        emailsDocentes: slot.docentes.map(docente => docente.email).join(', '),
        nombreSimulador: slot.simulador.nombre,
      }));
    };
    const fetchTimeSlots = async () => {
      loading.value = true;  // Establece loading a true al comenzar la carga
      try {
        const response = await axios.get(`reserved-time-slots/?month=${selectedDate.value.getMonth() + 1}&year=${selectedDate.value.getFullYear()}`);
        timeSlots.value = response.data;
        timeSlots.value = formatDates(timeSlots.value);
      } catch (error) {
        console.error("There was an error fetching the time slots:", error);
      } finally {
        loading.value = false;  // Establece loading a false al terminar la carga
      }
    };

    onMounted(() => {
      fetchTimeSlots();
    });

    return { headers, timeSlots, loading, t, previousMonth, nextMonth, selectedDate, currentMonth };
  },
}
</script>
