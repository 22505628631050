<template>
    <v-card>
        <v-tabs v-model="tab" bg-color="primary">
            <v-tab value="activitats" prepend-icon="mdi-school-outline">
                <span class="d-none d-sm-inline">{{ t('Actividades') }}</span></v-tab>
            <v-tab value="recursos" prepend-icon="mdi-cube-outline"><span class="d-none d-sm-inline">{{ t('Recursos')
                    }}</span></v-tab>
            <v-tab value="calendari" prepend-icon="mdi-calendar-month"><span class="d-none d-sm-inline">{{
            t('Calendario') }}</span></v-tab>
            <v-tab value="usuaris" prepend-icon="mdi-account-multiple"><span class="d-none d-sm-inline">{{
            t('Usuarios') }}</span></v-tab>
        </v-tabs>
        <v-card-text>
            <v-window disabled v-model="tab">
                <v-window-item value="activitats">
                    <AdminCursos></AdminCursos>
                </v-window-item>
                <v-window-item value="recursos">
                    <AdminRecursos></AdminRecursos>
                </v-window-item>
                <v-window-item value="calendari">
                    <AdminCalendari></AdminCalendari>
                </v-window-item>
                <v-window-item value="usuaris">
                    <AdminUsuaris></AdminUsuaris>
                </v-window-item>
            </v-window>
        </v-card-text>
    </v-card>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import AdminCursos from './AdminCursos.vue';
import AdminUsuaris from './AdminUsuaris.vue';
import AdminRecursos from './AdminRecursos.vue';
import AdminCalendari from './AdminCalendari.vue';
const { t } = useI18n();
</script>
<script>
export default {
    data: () => ({
        tab: null,
    }),
}
</script>