<template>
    <v-container>
        <v-form ref="form" v-model="valid">
            <v-text-field label="Título" v-model="plantilla.titulo" :rules="tituloRules" required></v-text-field>
            <v-select label="Tipo" :items="tipos" v-model="plantilla.tipo" required></v-select>
            <v-select label="Idioma" :items="idiomas" v-model="plantilla.idioma" required></v-select>
            <v-textarea label="Texto" v-model="plantilla.texto" required></v-textarea>
            <v-text-field label="Link (opcional)" v-model="plantilla.link"></v-text-field>
            <v-text-field label="Título del Link (sólo si pones link)" v-model="plantilla.titulo_link"></v-text-field>
            <v-btn :disabled="!valid" @click="submit">Crear</v-btn>
            <v-btn class="ma-4" icon @click="dialogAyuda = true">
                <v-icon>mdi-help-circle</v-icon>
            </v-btn>
        </v-form>
        <v-alert v-if="errorMessage" type="error" dismissible @input="errorMessage = ''">
            {{ errorMessage }}
        </v-alert>

        <!-- Diálogo de Ayuda -->
        <v-dialog v-model="dialogAyuda" max-width="600px">
            <v-card>
                <v-card-title>Ayuda</v-card-title>
                <v-card-text>
                    Para hacer que parte del texto de tu plantilla se vea en negrita, colócalo entre dos asteriscos (**).
                    Por ejemplo, **texto en negrita** se mostrará en negrita.
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" text @click="dialogAyuda = false">Cerrar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
  
<script>
import axios from 'axios'

export default {
    data() {
        return {
            valid: true,
            errorMessage: '',
            dialogAyuda: false,
            plantilla: {
                titulo: '',
                tipo: '',
                idioma: '',
                texto: '',
                link: '',
                titulo_link: ''
            },
            tituloRules: [
                v => !!v || 'El título es requerido',
                v => (v && v.length <= 200) || 'El título no debe exceder los 200 caracteres'
            ],
            tipos: ['PROCEDIMENTS', 'URGÈNCIES', 'CEX'],
            idiomas: ['CA', 'ES']
        }
    },
    methods: {
        async submit() {
            // Configura Axios para manejar el token CSRF
            axios.defaults.xsrfCookieName = 'csrftoken';  // Asegúrate de que coincida con el nombre de la cookie CSRF en Django
            axios.defaults.xsrfHeaderName = 'X-CSRFToken';
            // Crea un objeto FormData y agrega el token CSRF manualmente
            const formData = new FormData();
            formData.append('csrfmiddlewaretoken', this.getCookie('csrftoken')); // Aquí 'getCookie' es una función para obtener el valor de la cookie CSRF
            for (let key in this.plantilla) {
                formData.append(key, this.plantilla[key]);
            }

            if (this.$refs.form.validate()) {
                try {
                    const hostname = window.location.hostname;
                    const token = localStorage.getItem('authToken');
                    await axios.post(`https://${hostname}/api/plantilles/`, formData, {
                        headers: {
                            'Authorization': `Token ${token}`
                        }
                    });
                    this.$emit('update-complete');
                } catch (error) {
                    this.errorMessage = error.response.data.detail || 'Ha ocurrido un error';
                }
            }
        },
        getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
            return null;
        }

    }
}
</script>
  