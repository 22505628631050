import { createStore } from 'vuex';

export default createStore({
  state: {
    snackbar: {
      show: false,
      message: '',
      color: 'success'
    },
    selectedOptionVista: 'alumno',
  },
  mutations: {
    setSnackbar(state, payload) {
      state.snackbar.show = true;
      state.snackbar.message = payload.message;
      state.snackbar.color = payload.color || 'success';
    },
    clearSnackbar(state) {
      state.snackbar.show = false;
      state.snackbar.message = '';
    },
    SET_SELECTED_OPTION_VISTA(state, payload) { // Nueva mutación para actualizar selectedOptionVista
      state.selectedOptionVista = payload;
    },
  },
  actions: {
    triggerSnackbar({ commit }, payload) {
      commit('setSnackbar', payload);
      setTimeout(() => {
        commit('clearSnackbar');
      }, 5000); // Timeout for snackbar
    },
    setSelectedOptionVista({ commit }, payload) { // Nueva acción para despachar la mutación de selectedOptionVista
      commit('SET_SELECTED_OPTION_VISTA', payload);
    },
  },
  getters: {
    selectedOptionVista: (state) => state.selectedOptionVista, // Nuevo getter para acceder al estado de selectedOptionVista
  },
});