<template>
  <v-app-bar app>
    <template v-slot:prepend>
      <router-link to="/">
        <img :src="logoImage" alt="logo" style="height: 40px;">
      </router-link>
    </template>
    <v-app-bar-title class="headline text-uppercase">
      <span>{{ branca }}</span>
      <span class="font-weight-light">SIM</span>
    </v-app-bar-title>


    <!-- Botón del menú hamburguesa para móviles -->
    <v-menu>
      <template v-slot:activator="{ props }">
        <v-btn icon rounded="0" v-bind="props" class="h-100 hidden-sm-and-up">
          <v-app-bar-nav-icon></v-app-bar-nav-icon>
        </v-btn>
      </template>
      <v-list>
        <!-- Botón Explora -->
        <v-list-item @click="selectButton('explora', '/')">
          <v-btn flat rounded="0" class="h-100" :color="selectedButton === 'explora' ? 'primary' : 'default'">
            {{ t('Explora') }}
          </v-btn>
        </v-list-item>

        <!-- Botón Aprende -->
        <v-list-item @click="selectButton('aprende', '/actividades')">
          <v-btn flat rounded="0" class="h-100" :color="selectedButton === 'aprende' ? 'primary' : 'default'">
            {{ t('Aprende') }}
          </v-btn>
        </v-list-item>

        <!-- Botón Inspira -->
        <v-list-item @click="selectButton('inspira', '/inspira')">
          <v-btn flat rounded="0" class="h-100" :color="selectedButton === 'inspira' ? 'primary' : 'default'">
            {{ t('Inspira') }}
          </v-btn>
        </v-list-item>
        <!-- Botón Admin -->
        <v-list-item @click="selectButton('admin', '/administrador')">
          <v-btn v-if="isAdmin" flat rounded="0" class="h-100"
            :color="selectedButton === 'admin' ? 'primary' : 'default'">
            Admin
          </v-btn>
        </v-list-item>
      </v-list>
    </v-menu>
    <!-- Botons desktop -->
    <v-btn flat rounded="0" class="h-100 hidden-xs" :color="selectedButton === 'explora' ? 'primary' : 'default'"
      @click="selectButton('explora', '/')">
      {{ t('Explora') }}
    </v-btn>
    <v-btn flat rounded="0" class="h-100 hidden-xs" :color="selectedButton === 'aprende' ? 'primary' : 'default'"
      @click="selectButton('aprende', '/actividades')">
      {{ t('Aprende') }}
    </v-btn>
    <v-btn flat rounded="0" class="h-100 hidden-xs" :color="selectedButton === 'inspira' ? 'primary' : 'default'"
      @click="selectButton('inspira', '/inspira')">
      {{ t('Inspira') }}
    </v-btn>
    <!-- Botón Admin -->
    <v-btn v-if="isAdmin" flat rounded="0" class="h-100 hidden-xs"
      :color="selectedButton === 'admin' ? 'primary' : 'default'" @click="selectButton('admin', '/administrador')">
      Admin
    </v-btn>

    <v-menu>
      <template v-slot:activator="{ props }">
        <v-btn flat v-bind="props" rounded="0" class="h-100 hidden-xs">
          <v-icon>mdi-translate</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="setLocale('ca')">
          <v-list-item-title>Català</v-list-item-title>
        </v-list-item>
        <v-list-item @click="setLocale('en')">
          <v-list-item-title>English</v-list-item-title>
        </v-list-item>
        <v-list-item @click="setLocale('es')">
          <v-list-item-title>Español</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-menu>
      <template v-slot:activator="{ props }">
        <v-btn flat v-bind="props" rounded="0" class="h-100" prepend-icon="mdi-account">
          <span class="d-none d-sm-inline">{{ username }}</span>
        </v-btn>
      </template>

      <v-list>
        <v-list-item :to="`/perfil`" @click="resetButtons" prepend-icon="mdi-account-edit">
          <v-list-item-title>{{ t('Perfil') }}</v-list-item-title>
        </v-list-item>
        <v-list-item :to="`/reservas`" @click="resetButtons" prepend-icon="mdi-calendar-month">
          <v-list-item-title>{{ t('Tus_reservas') }}</v-list-item-title>
        </v-list-item>
        <v-list-item class="hidden-sm-and-up" prepend-icon="mdi-translate">{{ t('Idioma') }}
          <v-menu activator="parent">
            <v-list>
              <v-list-item @click="setLocale('ca')">
                <v-list-item-title>Català</v-list-item-title>
              </v-list-item>
              <v-list-item @click="setLocale('en')">
                <v-list-item-title>English</v-list-item-title>
              </v-list-item>
              <v-list-item @click="setLocale('es')">
                <v-list-item-title>Español</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item>
        <v-list-item @click="logout" prepend-icon="mdi-logout">{{ t('Cerrar_sesión') }}</v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { axios, setAuthToken } from '../axios';
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
export default {
  props: {
    branca: String,
    logoImage: String,
  },
  setup() {
    const selectedButton = ref(null);
    const router = useRouter();

    const { t, locale } = useI18n();
    // Recuperar el idioma guardado al cargar la aplicación
    const userLocale = localStorage.getItem('userLocale');
    if (userLocale) {
      locale.value = userLocale;
    }
    const selectButton = (button, path) => {
      selectedButton.value = button;
      router.push(path);
    };

    const resetButtons = () => {
      selectedButton.value = null;
    };

    return {
      t,
      locale,
      selectedButton,
      selectButton,
      resetButtons,
    };
  },
  data() {
    return {
      drawer: false,
      username: 'Usuario',
      isAdmin: false,
    };
  },
  mounted() {
    const token = localStorage.getItem('authToken');
    setAuthToken(token);
    this.fetchUser();
  },
  methods: {
    logout() {
      const formData = new FormData();
      axios.post(`logout/`, formData)
        .then(response => {
          console.log('Respuesta del servidor:', response.data);
          // Aquí manejas la respuesta exitosa
          localStorage.removeItem('authToken');
          this.username = 'Usuario';
          this.$router.push('/login');
        })
        .catch(error => {
          // Maneja errores aquí si es necesario
          console.error('Hubo un error al cerrar sesión: ', error);
        });

    },
    async fetchUser() {
      const userResponse = await axios.get(`userdetails/`, {
      });
      // Asegúrate de que las respuestas están en el formato correcto
      if (!userResponse.data) throw new Error("Formato de respuesta no válido");
      this.username = userResponse.data.nickname;
      this.isAdmin = userResponse.data.admin;
    },
    setLocale(newLocale) {
      this.locale = newLocale;
      localStorage.setItem('userLocale', newLocale);
    },

  }
}
</script>