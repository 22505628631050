<template>
  <v-col cols="12">
    <v-card class="mx-4">
      <v-toolbar flat>
        <v-toolbar-title>{{ t('Alumnos') }}</v-toolbar-title>
        <ExportExcel :dataItems="alumnos_total" />
      </v-toolbar>
      <v-text-field class="mx-4 mt-4" v-model="search" label="Cerca" prepend-inner-icon="mdi-magnify" single-line
        variant="outlined" hide-details></v-text-field>
      <v-data-table-virtual :headers="headers" :items="alumnos_total" :search="search" :loading="loading">
        <template v-slot:loading>
          <v-skeleton-loader type="table-row@10"></v-skeleton-loader>
        </template>
      </v-data-table-virtual>
    </v-card>
  </v-col>
  <v-col>
    <v-card class="mx-4">
      <v-card-title style="word-break: break-word;" class="text-wrap">{{ t('Alumnos_pendientes_de_evaluación') }}
        práctica</v-card-title>
      <v-card-text>
        <v-list dense v-if="alumnos_pendientes && alumnos_pendientes.length > 0">
          <v-list-item v-for="alumno in alumnos_pendientes" :key="alumno.username" @click="seleccionarAlumno(alumno)">
            <v-list-item-title>{{ alumno.first_name }} {{ alumno.last_name }}</v-list-item-title>
            <v-list-item-subtitle>{{ alumno.email }}</v-list-item-subtitle>
          </v-list-item>
        </v-list>
        <v-alert v-if="!alumnos_pendientes || alumnos_pendientes.length === 0" type="info">
          {{ t('No_hay_alumnos_pendientes_de_evaluación') }}
        </v-alert>
      </v-card-text>
    </v-card>
  </v-col>

  <!-- Dialog for student information -->
  <v-dialog v-model="dialog" persistent scrollable>
    <v-card>
      <v-card-title class="headline">{{ t('Evaluando_a') }} {{ alumnoSeleccionado.nombreCompleto }}</v-card-title>
      <div v-for="(cuestionario, index) in respuestas" :key="index">
        <v-card class="mx-4">
          <v-card-title>{{ cuestionario.titulo }}</v-card-title>
          <v-card-text>
            <div v-if="cuestionario.secuencias">
              <div v-for="secuencia in cuestionario.secuencias" :key="secuencia.id">
                <v-card class="mb-4">
                  <v-card-title>{{ secuencia.plano.nombre }} ({{ secuencia.posicion }})</v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-text-field v-model="secuencia.tiempo_inicial" label="Tiempo Inicial (mm:ss)" type="text"
                          @input="formatTime($event, 'tiempo_inicial', secuencia)">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field v-model="secuencia.tiempo_final" label="Tiempo Final (mm:ss)" type="text"
                          @input="formatTime($event, 'tiempo_final', secuencia)">
                        </v-text-field>
                      </v-col>
                      <v-list class="py-0" v-for="criterio in secuencia.plano.criterios" :key="criterio.id">
                        <v-checkbox v-model="criterio.seleccionado" :label="criterio.descripcion">
                        </v-checkbox>
                      </v-list>
                    </v-row>
                  </v-card-text>
                </v-card>
              </div>
            </div>
            <v-list dense>
              <v-list-item v-for="pregunta in cuestionario.preguntas" :key="pregunta.id">
                <v-img max-height="300" :src="pregunta.imagen"></v-img>
                <v-list-item class="text-h6 mt-4">{{ pregunta.texto }}</v-list-item>
                <div v-if="pregunta.tipo == 'open_ended'">
                  <v-textarea v-model="pregunta.texto_respuesta"></v-textarea>
                </div>
                <v-list class="py-0" v-for="opcion in pregunta.opciones" :key="opcion.id">
                  <v-list-item class="py-0">
                    <template v-slot:prepend>
                      <v-icon>{{ opcion.es_correcta ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}</v-icon>
                    </template>
                    <v-list-item-title>{{ opcion.texto }}</v-list-item-title>
                    <v-list-item-subtitle class="px-10" v-if="opcion.motivo">
                      {{ opcion.motivo }}
                    </v-list-item-subtitle>
                  </v-list-item>
                </v-list>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-alert type="error" v-if="errorMessage" dismissible @dismiss="errorMessage = ''">
            {{ errorMessage }}
          </v-alert>
          <v-card-actions>
            <v-btn @click="submitEvaluation">{{ t('Enviar_evaluación') }}</v-btn>
          </v-card-actions>
        </v-card>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false">{{ t('Cerrar') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import ExportExcel from './ExportExcel.vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { axios } from '../axios';

const { t } = useI18n();
const route = useRoute();

const dialog = ref(false);
const loading = ref(true);
const curso = ref({});
const cuestionarios = ref({});
const respuestas = ref({});
const alumnos_pendientes = ref([]);
const alumnos_total = ref([]);
const cursoId = ref(route.params.uid);
const search = ref('');
const errorMessage = ref('');
const headers = ref([
  { title: t('Nombre'), key: 'nombre' },
  { title: t('Apellidos'), key: 'apellidos' },
  { title: t('Cuestionario'), key: 'cuestionario_titulo' },
  { title: t('Nota'), key: 'nota' },
  { title: t('Intentos'), key: 'max_intento' },
  { title: t('Último'), key: 'fecha_ultimo_intento' },
]);

const alumnoSeleccionado = ref({ nombreCompleto: '', });


// Methods
const submitEvaluation = async () => {
  errorMessage.value = '';

  // Validate time format for each secuencia
  for (let secuencia of respuestas.value[0].secuencias) {
    if (!isValidTimeFormat(secuencia.tiempo_inicial) || !isValidTimeFormat(secuencia.tiempo_final)) {
      errorMessage.value = `Error en ${secuencia.plano.nombre} (${secuencia.posicion}): Algún campo de tiempo está vacío o tiene un formato incorrecto.`;
      return; // Stop the submission process and show the error
    }
  }

  // Construct the data object to send
  const datosParaEnviar = {
    respuestas_docente: respuestas.value,
    alumno: alumnoSeleccionado.value.id,
    curso: curso.value.id
  };
  try {
    const response = await axios.post(`enviar_respuestas_docente/`, datosParaEnviar);

    // Handle the server response
    if (response.data.success) {
      respuestas.value = {}; // Clean up on finish
    } else {
      errorMessage.value = response.data.message;
    }
  } catch (error) {
    errorMessage.value = error.response ? error.response.data.message : error.message;
  }
};

const formatTime = (event, field, secuencia) => {
  let value = event.target.value;

  // Remove all non-digit characters
  value = value.replace(/[^0-9]/g, '');

  // Ensure value is not longer than 4 characters (mmss)
  if (value.length > 4) {
    value = value.substr(0, 4);
  }

  // Add colon after two digits
  if (value.length > 2) {
    value = value.substr(0, 2) + ':' + value.substr(2);
  }

  // Update the corresponding field in secuencia
  secuencia[field] = value;
};

const isValidTimeFormat = (time) => {
  const timePattern = /^\d{2}:\d{2}$/;
  if (!timePattern.test(time)) {
    return false;
  }

  // Split the time into minutes and seconds
  const [minutes, seconds] = time.split(':').map(Number);

  // Check if minutes and seconds are within the range
  return minutes <= 59 && seconds <= 59;
};

const seleccionarAlumno = (alumno) => {
  alumnoSeleccionado.value.nombreCompleto = `${alumno.first_name} ${alumno.last_name}`;
  alumnoSeleccionado.value.id = alumno.id;
  formularQuestionariDocent();
  dialog.value = true;
};

const formularQuestionariDocent = () => {
  // Define what this function needs to do
  respuestas.value = cuestionarios.value;
  respuestas.value.forEach(cuestionario => {
    if (cuestionario.secuencias) {
      cuestionario.secuencias.forEach(secuencia => {
        secuencia.tiempo_inicial = '';
        secuencia.tiempo_final = '';
        secuencia.plano.criterios.forEach(criterio => {
          criterio.seleccionado = false;
        });
      });
    }
    if (cuestionario.preguntas) {
      cuestionario.preguntas.forEach(pregunta => {
        pregunta.texto_respuesta = '';
      });
    }
  });
};

const fetchPendentEval = async () => {
  try {
    const response = await axios.get(`curso/${cursoId.value}/alumnos_pendientes/`);
    alumnos_pendientes.value = response.data;
  } catch (error) {
    if (error.response && error.response.status === 403) {
      console.error("Error obtaining pending students:", error);
    } else {
      // Handle other types of errors
      alert("An error occurred while fetching pending students.");
    }
  }
};

const fetchAlumnes = async () => {
  loading.value = true;
  try {
    const response = await axios.get(`curso/${cursoId.value}/alumnos_total/`);
    alumnos_total.value = response.data;
    loading.value = false;
  } catch (error) {
    if (error.response && error.response.status === 403) {
      console.error("Error obtaining students:", error);
    } else {
      // Handle other types of errors
      alert("An error occurred while fetching students.");
    }
    loading.value = false;
  }
};

onMounted(() => {
  fetchPendentEval();
  fetchAlumnes();
});
</script>
