// src/axios.js o donde prefieras colocar tu configuración de Axios
import axios from 'axios';

const instance = axios.create({
    baseURL: `https://${window.location.hostname}/api/`,

});

// Function to get a cookie by name
function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
}

// Setting CSRF token from cookies to headers
instance.interceptors.request.use(config => {
    const csrfToken = getCookie('csrftoken');
    if (csrfToken) {
        config.headers['X-CSRFToken'] = csrfToken;
    }
    return config;
});

// Función para setear el token de autorización si existe
function setAuthToken(token) {
    if (token) {
        instance.defaults.headers.common['Authorization'] = `Token ${token}`;
    } else {
        delete instance.defaults.headers.common['Authorization'];
    }
}

export { instance as axios, setAuthToken };
