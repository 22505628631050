// router.js

import { createRouter, createWebHistory } from 'vue-router'
import UserLogin from './components/UserLogin.vue'
import UserRegister from './components/UserRegister.vue'
import HomePage from './components/HomePage.vue'
import NotFound from './components/NotFound.vue'
import UserDetails from './components/UserDetails.vue'
import ResetPassword from './components/ResetPassword.vue'
import PasswordResetComponent from './components/PasswordResetComponent.vue'
import UserActivitats from './components/UserActivitats.vue'
import CalendariReserva from './components/CalendariReserva.vue'
import TermsPrivacy from './components/TermsPrivacy.vue'
import InfoContact from './components/InfoContact.vue'
import ConfirmarEmail from './components/ConfirmarEmail.vue'
import ContenidoCurso from './components/ContenidoCurso.vue'
import ContenidoDocente from './components/ContenidoDocente.vue'
import UserInspira from './components/UserInspira.vue'
import UtilsPlantilles from './components/UtilsPlantilles.vue'
import AdminComponent from './components/AdminComponent.vue'


const routes = [
  { 
    path: '/', 
    component: HomePage, 
    name: 'Home',
    props: true, // This allows you to pass props to the component through route parameters
    meta: { requiresAuth: false } 
  },
  { path: '/inspira', component: UserInspira, meta: { requiresAuth: true } },
  { path: '/plantilles', component: UtilsPlantilles, meta: { requiresAuth: false }  },
  { path: '/login', component: UserLogin },
  { path: '/register', component: UserRegister },
  { path: '/actividades', component: UserActivitats, meta: { requiresAuth: true } },
  { path: '/curso/:uid', component: ContenidoCurso, meta: { requiresAuth: true } },
  { path: '/curso_inspira/:uid', component: ContenidoDocente, meta: { requiresAuth: true } },
  { path: '/perfil', component: UserDetails, meta: { requiresAuth: true } },
  { path: '/reservas', component: CalendariReserva, meta: { requiresAuth: true } },
  { path: '/condiciones-y-privacidad', component: TermsPrivacy },
  { path: '/contacto', component: InfoContact },
  { path: '/administrador', component: AdminComponent, meta: { requiresAuth: true } },
  { path: '/passreset', name: 'PasswordReset', component: PasswordResetComponent },
  { path: '/reset/:uid/:token', name: 'ResetPassword', component: ResetPassword },  
  { path: '/confirm-email/:key', name: 'ConfirmarEmail', component: ConfirmarEmail, props: true }, 
  { path: '/:catchAll(.*)', component: NotFound }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  // Comprueba si la ruta a la que se dirige el usuario requiere autenticación
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Comprueba si el usuario está autenticado
    if (!localStorage.getItem('authToken')) {
      // Si no está autenticado, redirige a la página de inicio de sesión
      next({ path: '/login' })
    } else {
      // Si está autenticado, permite el acceso a la ruta
      next()
    }
  } else {
    // Si la ruta no requiere autenticación, permite el acceso a la ruta
    next()
  }
})

export default router
